<template>
  <v-app>
    <div class="background"></div>
    <v-main class="d-flex justify-center align-center">
      <v-row class="mx-auto">
      <v-col cols="10" lg="4" class="mx-auto">
        <v-card class="pa-8" width="550" height="600">
          <div class="img-container">
            <v-img alt="CG3" src="../assets/CG3.webp" width="200" />
          </div>
          <br />
          <v-form @submit.prevent="Login">
            <v-card-text>
              <v-text-field
                v-model="item.usuario"
                :readonly="loading"
                :rules="[rules.required, rules.userStartsWithAt]"
                class="mb-2"
                label="Usuario"
                clearable
                prepend-inner-icon="mdi-account"
              ></v-text-field>
              <v-text-field
                v-model="item.contraseña"
                :rules="[rules.required, rules.password]"
                label="Contraseña"
                placeholder="Ingrese su contraseña"
                :type="show1 ? 'text' : 'password'"
                hint="Ingrese 8 caracteres"
                name="input-10-1"
                counter
                @click:append-inner="show1 = !show1"
                prepend-inner-icon="mdi-lock"
                :append-inner-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                clearable
              ></v-text-field>
              <v-alert
                v-if="error"
                density="compact"
                type="error"
                closable
                variant="tonal"
              >
                {{ error }}
              </v-alert>
              <br />
              <v-btn
                :loading="loading"
                color="success"
                size="large"
                type="submit"
                variant="elevated"
                block
              >
                Ingresar
              </v-btn>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>
<script>
import backedosic, { initCsrf } from '@/components/api/apiosic.js'
export default {
  name: 'LoginComponent',
  data: () => ({
    contraseña: null,
    rules: {
      required: value => !!value || 'Campo requerido.',
      password: value => value.length >= 8 || 'Contraseña debe tener al menos 8 caracteres',
      userStartsWithAt: value => {
        return value.startsWith('@') || 'El usuario debe comenzar con @'
      }
    },
    item: {
      usuario: '',
      contraseña: ''
    },
    loading: false,
    error: '',
    show1: false,
    user: []
  }),
  methods: {
    async Login () {
      if (this.item.usuario && this.item.usuario.startsWith('@') && this.item.contraseña && this.item.contraseña.length >= 8) {
        this.loading = true
        this.error = ''

        try {
          // Llama a initCsrf para configurar el token CSRF antes de hacer login
          await initCsrf()

          const loginResponse = await backedosic.post('/login', {
            usuario: this.item.usuario,
            contraseña: this.item.contraseña
          })

          this.loading = false
          localStorage.setItem('token', loginResponse.data.token)

          // Llama a getUsuario() solo después de un login exitoso
          await this.getUsuario()
          // Redirige basado en el tipo de usuario
          if (this.user.idTipoUsuario === 1) {
            this.$router.push('/panelUsuario/ordenes')
          } else if (this.user.idTipoUsuario === 2) {
            this.$router.push('/panelAdmin/ordenes')
          } else if (this.user.idTipoUsuario === 3) {
            this.$router.push('/panelFacturacion/ordenes')
          }
        } catch (error) {
          this.loading = false
          if (error.response && error.response.status === 401) {
            this.error = 'Credenciales incorrectas'
          } else if (error.response && error.response.status === 403) {
            this.error = error.response.data.message
          } else {
            console.log(error.response)
            this.error = 'Error en el servidor. Inténtelo de nuevo más tarde.'
          }
        }
      }
    },
    async getUsuario () {
      try {
        const response = await backedosic.post('/usuario/by-username', {
          usuario: this.item.usuario// Enviamos el nombre de usuario desde el input
        })
        this.user = response.data // Guardamos la información del usuario
        this.error = null // Limpiamos cualquier error previo
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.error = 'Credenciales incorrectas'
        } else {
          this.error = 'Ocurrió un error al buscar el usuario'
        }
        this.user = null // Limpiamos los datos del usuario si hay un error
      }
    }
  }
}
</script>
<style>
.background {
  background-color: #06a454;
  height: 300px;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  background-size: cover;
}
.img-container {
  justify-content: center;
  display: flex;
  height: 120px;
  width: 100%;
}
</style>
