<template>
  <OrdenesFacturacion endpoint="/ordenNoPagados"></OrdenesFacturacion>
</template>

<script>
import { defineComponent } from 'vue'

import OrdenesFacturacion from '../components/OrdenesFacturacion.vue'

export default defineComponent({
  name: 'OrdenesNPFacturacionView',

  components: {
    OrdenesFacturacion
  }
})
</script>
