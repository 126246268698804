<template>
  <v-app>
    <br />
    <v-card-title class="text-h4 font-weight-medium" style="margin-left: 20px">
      Ordenes
    </v-card-title>
    <v-row style="margin-left: 1000px;">
      <v-col>
      <v-text-field
        v-model="search"
        label="Search"
        prepend-inner-icon="mdi-magnify"
        hide-details
        single-line
        variant="outlined"
      ></v-text-field>
      </v-col>
    <div>
      <v-col>
      <v-dialog v-model="dialog" class="pa-4 text-center" persistent>
        <template v-slot:activator="{ props: activatorProps }">
          <div class="bottonusuarioOrdenes">
          <v-btn size="large" color="#06a454" dark v-bind="activatorProps" @click="resetTempColPapels">
            Nueva orden
          </v-btn>
          </div>
        </template>
        <div class="cardOrden">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <div v-if="step === 1">
              <v-btn class="mr-5 my-5" size="large" elevation="12" color="#06a454" dark @click="showStep(2)">
                Impresión
              </v-btn>
              <v-btn  class="my-5 ml-5" size="large" elevation="12" color="#06a454" dark @click="showStep(3)">
                Sello
              </v-btn>
            </div>
            <div v-if="step === 2">
              <v-container>
                <v-row>
                  <v-col>
                    <v-checkbox v-model="editedItem.urgente" label=Urgente: :true-value="1" :false-value="0"></v-checkbox>
                  </v-col>
                  <row>
                    <v-col cols="6" md="2" sm="3">
                    <v-label
                    >{{ editedItem.fechaHoraOrden }}</v-label>
                  </v-col>
                  </row>
                  <row>
                    <v-col cols="6" md="2" sm="3">
                    <v-label
                    >{{ editedItem.folio }}</v-label>
                  </v-col>
                  </row>
                  </v-row>
                  <v-row>
                  <v-col>
                    <v-combobox v-model="editedItem.idCliente" :items="clientes" item-title="nomCliente"  item-value="idCliente"
                      label="Cliente" @update:modelValue="onChageCliente" clearable :rules="[rules.required]">
                    </v-combobox>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col>
                    <v-combobox
                      v-model="editedItem.idOrdenado"
                      label="Ordenado"
                      :items="ordenados"
                      item-title="nomOrdenado"
                      item-value="idOrdenado"
                      :disabled="!editedItem.idCliente || loadingOrdenados"
                      @update:modelValue="fetchTelOrdenado"
                      :loading="loadingOrdenados"
                      clearable
                      :rules="[rules.required]"
                      >
                    </v-combobox>
                  </v-col>
                  <v-col cols="10" md="3" sm="5">
                    <v-text-field
                      v-model="editedItem.telOrdenado"
                      :disabled="!editedItem.idOrdenado"
                      label="Telefono"
                      :rules="[rules.onlyTenNumbers]"
                    ></v-text-field>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col>
                    <v-autocomplete
                      label="Forma"
                      :items="formas"
                      item-title="nombreForma"
                      item-value="idForma"
                      v-model = "editedItem.idForma"
                      :rules="[rules.required]"
                      ></v-autocomplete>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col>
                    <v-checkbox v-model="editedItem.aDomicilio" label="a Domicilio:" :true-value="1" :false-value="0"></v-checkbox>
                  </v-col>
                  <v-col cols="7" md="3" sm="4">
                    <VueDatePicker v-model="editedItem.fechaEntregaOrden" :enable-time-picker="false" :week-numbers="{ type: 'iso' }" :min-date="new Date()" :max-date="maxDate" placeholder="Fecha entrega"/>
                  </v-col>
                  <v-col cols="7" md="3" sm="4">
                    <VueDatePicker v-model="editedItem.horaEntregaOrden" time-picker position="right" placeholder="Hora"/>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" variant="text" @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="#06a454" variant="text" @click="showStep(4)">
                  Siguiente
                </v-btn>
              </v-card-actions>
            </div>
            <div v-if="step === 3">
              <v-container>
                <v-row>
                  <v-col>
                    <v-checkbox v-model="editedItem.urgente" label="Urgente:" :true-value="1" :false-value="0"></v-checkbox>
                  </v-col>
                  <row>
                    <v-col cols="6" md="2" sm="3">
                    <v-label
                    >{{ editedItem.fechaHoraOrden }}</v-label>
                  </v-col>
                  </row>
                  <row>
                    <v-col cols="6" md="2" sm="3">
                    <v-label
                    >{{ editedItem.folio }}</v-label>
                  </v-col>
                  </row>
                  </v-row>
                  <v-row>
                  <v-col>
                    <v-combobox
                    v-model="editedItem.idCliente"
                    label="Cliente"
                    :items="clientes"
                    item-title="nomCliente"
                    item-value="idCliente"
                    @update:modelValue="onChageCliente"
                    clearable
                    :rules="[rules.required]"
                    ></v-combobox>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col>
                    <v-combobox
                      v-model="editedItem.idOrdenado"
                      label="Ordenado"
                      :items="ordenados"
                      item-title="nomOrdenado"
                      item-value="idOrdenado"
                      :disabled="!editedItem.idCliente|| loadingOrdenados"
                      @update:modelValue="fetchTelOrdenado"
                      :loading="loadingOrdenados"
                      clearable
                      :rules="[rules.required]"
                      ></v-combobox>
                  </v-col>
                  <v-col cols="10" md="3" sm="5">
                    <v-text-field
                      v-model="editedItem.telOrdenado"
                      :disabled="!editedItem.idOrdenado"
                      label="Telefono"
                      :rules="[rules.onlyTenNumbers]"
                    ></v-text-field>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col>
                    <v-checkbox v-model="editedItem.aDomicilio" label="a Domicilio:" :true-value="1" :false-value="0"></v-checkbox>
                  </v-col>
                  <v-col cols="7" md="3" sm="4">
                    <VueDatePicker v-model="editedItem.fechaEntregaOrden" :enable-time-picker="false" :week-numbers="{ type: 'iso' }"  :min-date="new Date()" :max-date="maxDate" placeholder="Fecha entrega"/>
                  </v-col>
                  <v-col cols="7" md="3" sm="4">
                    <VueDatePicker v-model="editedItem.horaEntregaOrden" time-picker position="right" placeholder="Hora"/>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" variant="text" @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="#06a454" variant="text" @click="showStep(6)">
                  Siguiente
                </v-btn>
              </v-card-actions>
            </div>
            <div v-else-if="step === 4">
              <v-container>
                <v-row>
                  <v-col cols="10" md="3" sm="3">
                    <v-text-field
                      v-model="editedItem.cantidad"
                      label="cantidad"
                      :rules="[rules.required, rules.onlyNumbers]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10" md="2" sm="3">
                    <v-text-field
                      v-model="editedItem.numTintas"
                      label="Tintas"
                      :rules="[rules.required, rules.onlySpecificNumbers]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10" md="4" sm="3">
                  <v-combobox
                    multiple
                    label="Color tintas"
                    v-model="editedItemTintas.idTinta"
                    :items="tintas"
                    item-title="nombreTinta"
                    item-value="idTinta"
                  ></v-combobox>
                  </v-col>
                  <v-col cols="10" md="3" sm="3">
                    <v-checkbox v-model="editedItem.selColor" label="S/color" :true-value="1" :false-value="0"></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Papel"
                      :items="papels"
                      item-title="nombrePapel"
                      item-value="idPapel"
                      v-model="editedItem.idPapel"
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Tamaño"
                      :items="tamanos"
                      item-title="nombreTamano"
                      item-value="idTamano"
                      v-model="editedItem.idTamano"
                      ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" sm="6">
                    <v-checkbox v-model="editedItem.diseno" label="diseño:" value="1" :true-value="1" :false-value="0"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4" sm="6">
                    <v-checkbox v-model="editedItem.muestra" label="Muestra:" value="1" :true-value="1" :false-value="0"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4" sm="6">
                    <v-checkbox v-model="editedItem.cambios" label="Cambios:" value="1" :true-value="1" :false-value="0"></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Impresion"
                      :items="impresions"
                      item-title="TipoImpresion"
                      item-value="idImpresion"
                      v-model="editedItem.idImpresion"
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Tipo Forma"
                      :items="tipoFormas"
                      item-title="nombreTipoForma"
                      item-value = "idTipoForma"
                      v-model="editedItem.idTipoForma"
                      ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="10" md="3" sm="3">
                    <v-text-field
                      v-model="editedItem.folioIn"
                      label="folioIn"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="3">
                    <v-text-field
                      v-model="editedItem.folioFin"
                      label="folioFin"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="3">
                    <v-text-field
                      v-model="editedItem.serie"
                      label="serie"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="3">
                    <v-text-field
                      v-model="editedItem.blocks"
                      label="blocks"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" variant="text" @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="gray-darken-1" variant="text" @click="showStep(2)">
                  Atras
                </v-btn>
                <v-btn color="#06a454" variant="text" @click="showStep(8)">
                  Siguiente
                </v-btn>
              </v-card-actions>
            </div>
            <div v-else-if="step === 5">
              <v-container>
                <v-row>
                  <v-col>
                    <v-textarea
                    v-model="editedItem.observacionesOrden"
                    bg-color="grey-lighten-2"
                    label="Observaciones"
                  ></v-textarea>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col cols="14" md="6" sm="8">
                    <v-text-field
                        label="Elaboró"
                        v-model="getNombreUsuario"
                        readonly
                        outlined
                      ></v-text-field>
                  </v-col>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Diseño"
                      :items="usuarios"
                      item-title="nomUsuario"
                      item-value="idUsuario"
                      :disabled="true"
                      ></v-autocomplete>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Reviso"
                      :items="usuarios"
                      item-title="nomUsuario"
                      item-value="idUsuario"
                      :disabled="true"
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Imprimio"
                      :items="usuarios"
                      item-title="nomUsuario"
                      item-value="idUsuario"
                      :disabled="true"
                      ></v-autocomplete>
                  </v-col>
                  </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" variant="text" @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="gray-darken-1" variant="text" @click="showStep(8)">
                  Atras
                </v-btn>
                <v-btn color="#06a454" variant="text" @click="saveOrdImpresion ">
                  Guardar
                </v-btn>
              </v-card-actions>
            </div>
            <div v-else-if="step === 6">
              <!-- Aquí irían los campos para los sellos -->
              <v-container>
                <v-row>
                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.cantidad"
                      label="cantidad"
                      :rules="[rules.required, rules.onlyNumbers]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.nombreSellos"
                      label="Sello"
                      :rules="[rules.required]"
                    ></v-text-field>
                    </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.tipo"
                      label="Tipo"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.medida"
                      label="Medida"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" variant="text" @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="gray-darken-1" variant="text" @click="showStep(3)">
                  Atras
                </v-btn>
                <v-btn color="#06a454" variant="text" @click="showStep(7)">
                  Siguiente
                </v-btn>
              </v-card-actions>
            </div>
            <div v-else-if="step === 7">
              <v-container>
                <v-row>
                  <v-col>
                    <v-textarea
                    v-model="editedItem.observacionesOrden"
                    bg-color="grey-lighten-2"
                    label="Observaciones"
                  ></v-textarea>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col cols="14" md="6" sm="8">
                    <v-text-field
                        label="Elaboró"
                        v-model="getNombreUsuario"
                        readonly
                        outlined
                      ></v-text-field>
                  </v-col>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Diseño"
                      :items="usuarios"
                      item-title="nomUsuario"
                      item-value="idUsuario"
                      :disabled="true"
                      ></v-autocomplete>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Reviso"
                      :items="usuarios"
                      item-title="nomUsuario"
                      item-value="idUsuario"
                      :disabled="true"
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Imprimio"
                      :items="usuarios"
                      item-title="nomUsuario"
                      item-value="idUsuario"
                      :disabled="true"
                      ></v-autocomplete>
                  </v-col>
                  </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" variant="text" @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="gray-darken-1" variant="text" @click="showStep(6)">
                  Atras
                </v-btn>
                <v-btn color="#06a454" variant="text" @click="saveOrdSellos">
                  Guardar
                </v-btn>
              </v-card-actions>
            </div>
            <div v-else-if="step === 8">
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="editedItemColPapels.colorPapel"
                      label="color papel"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="editedItemColPapels.kgs"
                      label="kgs"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="editedItemColPapels.marginales"
                      label="marginales"
                    ></v-text-field>
                  </v-col>
                  <div class="justify-end">
                  <v-col cols="12">
                      <v-btn
                      color="#06a454"
                      @click="addColPapels"
                      icon="mdi-plus"
                      size="small"
                      >
                    </v-btn>
                  </v-col>
                </div>
                </v-row>
                <v-row>
                  <v-data-table-virtual
                      class="tabla"
                      :headers="headersColPapels"
                      :items="colPapels"
                      :sort-by="[{ key: 'fecha', order: 'asc'}]"
                      :padding="100"
                      item-key="idColPapel"
                    >
                      <template v-slot:top></template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-icon size="small" @click="deleteItemColsPapels(item)">mdi-delete</v-icon>
                      </template>
                    </v-data-table-virtual>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" variant="text" @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="gray-darken-1" variant="text" @click="showStep(4)">
                  Atras
                </v-btn>
                <v-btn color="#06a454" variant="text" @click="showStep(5)">
                  Siguiente
                </v-btn>
              </v-card-actions>
            </div>
            </v-card-text>
        </v-card>
        </div>
      </v-dialog>
      </v-col>
      <v-dialog v-model="showErrorDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="text-center">
          <v-icon color="red" large class="mr-2">mdi-alert-circle</v-icon>
          Atención</v-card-title>
          <v-card-text class="text-center">
            Por favor, Complete todos los campos obligatorios antes de continuar.
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#06a454" text @click="showErrorDialog = false">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px" persistent>
        <v-card>
          <v-card-title class="text-center">
            ¿Seguro quieres eliminar esta orden?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="gray-darken-1" variant="text" @click="closeDelete">
              Cancelar
            </v-btn>
            <v-btn color="#06a454" variant="text" @click="deleteItemConfirm">
              Eliminar
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Diálogo para observaciones -->
      <v-dialog v-model="observacionesDialog" class="cardOrden" persistent>
        <v-card>
          <v-card-title>Observaciones para Cliente</v-card-title>
          <v-card-text>
            <!-- Información de la orden (no editable) -->
            <v-container>
              <v-row class="justify-end">
                  <row>
                    <v-col cols="6" md="2" sm="3">
                    <v-label
                    >{{ editedItem.fechaHoraOrden }}</v-label>
                  </v-col>
                  </row>
                  <row>
                    <v-col cols="6" md="2" sm="3">
                    <v-label
                    >{{ editedItem.folio }}</v-label>
                  </v-col>
                  </row>
              </v-row>
                  <v-row>
                  <v-col>
                    <v-combobox v-model="editedItem.idCliente" :items="clientes" item-title="nomCliente"  item-value="idCliente"
                      label="Cliente" @update:modelValue="onChageCliente" readonly
                      outlined :rules="[rules.required]">
                    </v-combobox>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col>
                    <v-combobox
                      v-model="editedItem.idOrdenado"
                      label="Ordenado"
                      :items="ordenados"
                      item-title="nomOrdenado"
                      item-value="idOrdenado"
                      :disabled="!editedItem.idCliente || loadingOrdenados"
                      @update:modelValue="fetchTelOrdenado"
                      :loading="loadingOrdenados"
                      readonly
                      outlined
                      :rules="[rules.required]"
                      >
                    </v-combobox>
                  </v-col>
                  <v-col cols="10" md="3" sm="5">
                    <v-text-field
                      v-model="editedItem.telOrdenado"
                      :disabled="!editedItem.idOrdenado"
                      label="Telefono"
                      :rules="[rules.onlyTenNumbers]"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col>
                    <v-autocomplete
                      label="Forma"
                      :items="formas"
                      item-title="nombreForma"
                      item-value="idForma"
                      v-model = "editedItem.idForma"
                      :rules="[rules.required]"
                      readonly
                      outlined
                      ></v-autocomplete>
                  </v-col>
                  </v-row>
                  <v-row>
                  <!-- Campo para observaciones (editable) -->
                    <v-textarea
                      v-model="editedItem.observacionesCliente"
                      bg-color="grey-lighten-2"
                      label="Observaciones"
                      outlined
                    ></v-textarea>
                  </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="gray-darken-1" variant="text" @click="closeObservacionesDialog">
              Cancelar
            </v-btn>
            <v-btn color="#06a454" variant="text" @click="saveObservaciones">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    </v-row>
    <v-card-text>
      <v-data-table
        class="tabla tablaTam"
        :headers="headers"
        :items="ordenes"
        :sort-by="[{ key: 'fecha', order: 'asc'}]"
        :padding="100"
        item-key="idOrden"
        :search="search"
        :custom-filter="filtrar"
        v-model="ordenSeleccionada"
        single-select
      >
        <template v-slot:[`item.auxUrgente`] ="{ value }">
          <v-chip :color="getColor(value)" variant="tonal" size="large">
            {{ value }}
          </v-chip>
        </template>
        <template v-slot:[`item.nomEstado`] ="{item}">
          <template v-if="!item.showSelect">
            <v-chip :color="getEstado(item.nomEstado)" class="ma-2" label size="large" @click="toggleSelect(item)" :disabled="item.nomEstado === 'Entregado'">
            {{ item.nomEstado }}
          </v-chip>
          </template>
          <tr v-else>
                <td colspan="2">
                    <v-select v-model="item.nomEstado" :items="estados" item-title="nomEstado" item-value="idEstado" @update:model-value="handleOptionChange(item)"
                    @blur="toggleSelect(item)"></v-select>
                </td>
          </tr>
        </template>
        <template v-slot:top></template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="me-2" size="small" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon class="me-2" size="small" @click="imprimir(item)">mdi-printer</v-icon>
          <v-icon v-if="isTipoUsuarioAdmin"  class="me-2" size="small" @click="deleteItem(item)">mdi-delete</v-icon>
          <v-menu :location="end">
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props" class="me-2"
              > mdi-dots-vertical
              </v-icon>
            </template>
            <v-list>
               <!-- Editar -->
                <v-list-item @click="editItem(item)">
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item>

                <!-- Generar orden de entrega -->
                <v-list-item @click="generaOrdEntrega(item)">
                  <v-list-item-title>Generar orden de entrega</v-list-item-title>
                </v-list-item>

                <!-- Imprimir -->
                <v-list-item @click="imprimir(item)">
                  <v-list-item-title>Imprimir</v-list-item-title>
                </v-list-item>

                <!-- Generar PDF -->
                <v-list-item @click="generatePDF(item)">
                  <v-list-item-title>Generar PDF</v-list-item-title>
                </v-list-item>

                <!-- Añadir observaciones para clientes -->
                <v-list-item @click="openObservacionesDialog(item)">
                  <v-list-item-title>Observaciones para cliente</v-list-item-title>
                </v-list-item>

                <!-- Eliminar (solo para administradores) -->
                <v-list-item
                  v-if="isTipoUsuarioAdmin"
                  @click="deleteItem(item)"
                >
                  <v-list-item-title>Eliminar</v-list-item-title>
                </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">Reset</v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-app>
</template>

<script>
import backedosic from '@/components/api/apiosic.js'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { ref } from 'vue'
import { jsPDF } from 'jspdf'

export default {
  name: 'OrdenesComponent',
  components: { VueDatePicker },
  props: {
    endpoint: {
      type: String,
      required: true
    }
  },
  data: () => ({
    observacionesDialog: false,
    loadingOrdenados: false,
    search: '',
    date: null,
    showSelect: false,
    maxDate: ref(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() + 15)),
    dialog: false,
    dialogDelete: false,
    step: 1,
    headers: [
      {
        title: 'Folio',
        align: 'start',
        sortable: false,
        key: 'folio',
        background: 'red-lighten-5'
      },
      { title: 'Forma', key: 'nombreForma', class: 'my-header-style' },
      { title: 'Cantidad', key: 'cantidad' },
      { title: 'Fecha', key: 'fechaHoraOrden' },
      { title: 'Fecha entrega', key: 'fechaEntregaOrden' },
      { title: 'Cliente', key: 'nombreCliente' },
      { title: 'Ordeno', key: 'nombreOrdenado' },
      { title: 'Usuario', key: 'nomUsuario' },
      { title: 'Urgente', key: 'auxUrgente' },
      { title: 'Estado', key: 'nomEstado' },
      { title: '     ', key: 'actions', sortable: false, width: '150px' }
    ],
    headersColPapels: [
      { title: 'Color papel', key: 'colorPapel' },
      { title: 'kgs', key: 'kgs' },
      { title: 'marginales', key: 'marginales' },
      { title: '     ', key: 'actions', sortable: false }
    ],
    editedItemColPapels: {
      colorPapel: '',
      kgs: '',
      marginales: '',
      idColPapel: '',
      idEspecificacionesForma: ''
    },
    editedItemColPapelsAux: {
      colorPapel: '',
      kgs: '',
      marginales: '',
      idColPapel: '',
      idEspecificacionesForma: ''
    },
    editedItemTintas: {
      idTinta: [],
      nombreTinta: []
    },
    editedItemTintasAux: {
      idTinta: [],
      nombreTinta: []
    },
    tintas: [],
    ordenes: [],
    ordenadosFiltrados: [],
    ordenadoTelefono: '',
    editedIndex: -1,
    editedIndexAux: -1,
    editedItem: {
      placa: '1',
      numTintas: '',
      selColor: '',
      diseno: '',
      muestra: '',
      cambios: '',
      folioIn: '',
      folioFin: '',
      serie: '',
      blocks: '',
      idImpresion: '',
      TipoImpresion: '',
      idPapel: '',
      nombrePapel: '',
      idTipoForma: '',
      nombreTipoForma: '',
      idTamano: '',
      nombreTamano: '',
      idForma: '',
      nombreForma: '',
      folio: '',
      cantidad: '',
      fechaHoraOrden: '',
      fechaEntregaOrden: '',
      horaEntregaOrden: '',
      aDomicilio: '',
      urgente: '',
      observacionesOrden: '',
      observacionesCliente: '',
      idOrdenado: '',
      nomOrdenado: '',
      telOrdenado: '',
      idCliente: '',
      nomCliente: '',
      idUsuario: '',
      nomUsuario: '',
      idSellos: '',
      medida: '',
      tipo: '',
      idEstado: '',
      idEspecificacionesForma: ''
    },
    defaultItem: {
      placa: '1',
      numTintas: '',
      selColor: '',
      diseno: '',
      muestra: '',
      cambios: '',
      folioIn: '',
      folioFin: '',
      serie: '',
      blocks: '',
      idImpresion: '',
      TipoImpresion: '',
      idPapel: '',
      nombrePapel: '',
      idTipoForma: '',
      nombreTipoForma: '',
      idTamano: '',
      nombreTamano: '',
      idForma: '',
      nombreForma: '',
      folio: '',
      cantidad: '',
      fechaHoraOrden: '',
      fechaEntregaOrden: '',
      horaEntregaOrden: '',
      aDomicilio: '',
      urgente: '',
      observacionesOrden: '',
      observacionesCliente: '',
      idOrdenado: '',
      nomOrdenado: '',
      telOrdenado: '',
      idCliente: '',
      nomCliente: '',
      idUsuario: '',
      nomUsuario: '',
      idSellos: '',
      medida: '',
      tipo: '',
      idEstado: '',
      idEspecificacionesForma: ''
    },
    clientes: [],
    ordenados: [],
    formas: [],
    papels: [],
    tamanos: [],
    impresions: [],
    tipoFormas: [],
    usuarios: [],
    colPapels: [],
    colPapelsAux: [],
    estados: [],
    expandedItems: [],
    ordenadoTel: [],
    ordenSeleccionada: null,
    rules: {
      required: value => !!value || 'Campo requerido.',
      onlyNumbers: value => {
        const regex = /^[0-9]+$/
        return regex.test(value) || 'Solo admite numeros'
      },
      onlyTenNumbers: value => {
        if (value === null || value === undefined || value === '') {
          return true
        }
        const regex = /^[0-9]{10}$/
        return regex.test(value) || 'Debe tener 10 números'
      },
      onlySpecificNumbers: value => {
        const allowedNumbers = [1, 2, 3, 4]
        return allowedNumbers.includes(Number(value)) || 'Solo se permiten los números 1, 2, 3 o 4'
      }
    },
    showErrorDialog: false,
    nextId: 1
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nueva Orden' : 'Editar Orden'
    },
    getNombreUsuario () {
      return this.editedIndex === -1 ? this.nombreUsuario.nombre : this.editedItem.nomUsuario
    },
    nombreUsuario () {
      const user = this.$store.state.user
      return {
        nombre: user?.nombre || 'Usuario',
        idUsuario: user?.idUsuario || null, // Retorna null si idUsuario no existe
        tipoUsuario: user?.tipoUsuario || null
      }
    },
    isTipoUsuarioAdmin () {
      return this.nombreUsuario.tipoUsuario === 2 // cambiar por el idTipoUsuario corrector
      //  en el despliegue
    }
  },
  mounted () {
    this.$store.dispatch('fetchUser')
      .then(() => {
        console.log('usuario después de fetchUser:', this.$store.state.user)
      })
      .catch(error => {
        console.error('Error al obtener el usuario:', error)
      })
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    /* 'editedItem.idCliente': function (idCliente) {
      if (this.editedItem || this.editedItem.idCliente || this.editedItem.idOrdenado) {
        if (this.editedIndex > -1) {
          this.getOrdenadosPorCliente(this.editedItem.idCliente)
          if (idCliente) {
            this.getOrdenadosPorCliente(this.editedItem.idCliente.idCliente)
          } else {
            this.ordenados = []
            this.editedItem.idOrdenado = ''
            this.editedItem.nomOrdenado = ''
          }
        } else {
          if (idCliente) {
            this.getOrdenadosPorCliente(this.editedItem.idCliente.idCliente)
          } else {
            this.ordenados = []
            this.editedItem.idOrdenado = ''
            this.editedItem.nomOrdenado = ''
          }
        }
      } else {
        this.editedItem = {}
        this.editedItem.idCliente = ''
        this.editedItem.idOrdenado = ''
        console.error('"editedItem es null o idOrdenado no está definido"')
      }
    } */
    'editedItem.idCliente': function (idCliente) {
      if (!this.editedItem) {
        console.error('"editedItem es null o idOrdenado no está definido"')
        return
      }

      if (!idCliente) {
        this.ordenados = []
        this.editedItem.idOrdenado = ''
        this.editedItem.nomOrdenado = ''
        return
      }

      // Tomar el ID correcto, sea número o un objeto con ID
      const id = typeof idCliente === 'object' ? idCliente.idCliente : idCliente

      if (!id) {
        this.ordenados = []
        this.editedItem.idOrdenado = ''
        this.editedItem.nomOrdenado = ''
        return
      }
      // Llamar solo una vez a la API
      this.getOrdenadosPorCliente(id)
    }
  },

  created () {
    this.initialize()
  },
  methods: {
    initialize () {
      backedosic.get(this.endpoint).then(r => {
        this.ordenes = r.data
        return Promise.all([
          this.mostrarUrgentes(),
          this.getCliente(),
          this.getOrdenado(),
          this.getForma(),
          this.getPapel(),
          this.getTamano(),
          this.getImpresion(),
          this.getTipoForma(),
          this.getTintas(),
          this.getUsuario(),
          this.getEstados(),
          this.getOrdenadobyID(),
          this.getOrdenadosPorCliente()
        ])
      })
    },
    async getColPapels () {
      this.colPapels = []
      this.editedItemColPapels.idEspecificacionesForma = String(this.nombreUsuario.idUsuario)
      this.editedItem.idEspecificacionesForma = this.editedItem.idEspecificacionesForma || '0'
      if (this.editedItem && this.editedItem.idEspecificacionesForma) {
        backedosic.get(`/getColPapelsByID/${this.editedItemColPapels.idEspecificacionesForma}/${this.editedItem.idEspecificacionesForma}`).then(r => {
          this.colPapels = r.data
        }).catch(error => {
          console.error('Error:', error.response ? error.response.data : error.message)
        })
      } else {
        console.warn('idEspecificacionesForma no está definido, no se puede hacer la solicitud.')
      }
    },
    async getEspecformatinta (item) {
      try {
        const response = await backedosic.get(`/especforma-tinta/${item.idEspecificacionesForma}`)
        this.editedItemTintas.idTinta = response.data.map(tinta => ({
          idTinta: tinta.idTinta,
          nombreTinta: tinta.nombreTinta
        }))
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log('No se encontraron tintas para esta especificación.')
          this.editedItemTintas.idTinta = []
        } else {
          console.log('Error al obtener las tintas:', error)
        }
      }
    },
    mostrarUrgentes () {
      this.ordenes = this.ordenes.map(orden => {
        return {
          ...orden,
          auxUrgente: orden.urgente === 1 ? 'Urgente' : 'No urgente'
        }
      })
    },
    async getCliente () {
      this.clientes = []
      try {
        const response = await backedosic.get('/cliente')
        response.data.forEach(e => {
          this.clientes.push({ idCliente: e.idCliente, nomCliente: e.nomCliente })
        })
      } catch (error) {
        console.error('Error al obtener los clientes:', error)
      }
    },

    async getTintas () {
      this.tintas = []
      try {
        const response = await backedosic.get('/tinta')
        response.data.forEach(e => {
          this.tintas.push({ idTinta: e.idTinta, nombreTinta: e.nombreTinta })
        })
      } catch (error) {
        console.error('Error al obtener las tintas:', error)
      }
    },

    async getOrdenado () {
      if (this.ordenados.length === null) {
        try {
          const response = await backedosic.get('/ordenado')
          response.data.forEach(e => {
            this.ordenados.push({ idOrdenado: e.idOrdenado, nomOrdenado: e.nomOrdenado || `Ordenado ${e.idOrdenado}`, telOrdenado: e.telOrdenado })
          })
        } catch (error) {
          console.error('Error al obtener los ordenados:', error)
        }
      }
    },
    getForma () {
      this.formas = []
      backedosic.get('/forma').then(r => {
        r.data.forEach(e => {
          this.formas.push({ idForma: e.idForma, nombreForma: e.nombreForma })
        })
      })
    },
    getPapel () {
      this.papels = []
      backedosic.get('/papel').then(r => {
        r.data.forEach(e => {
          this.papels.push({ idPapel: e.idPapel, nombrePapel: e.nombrePapel })
        })
      })
    },
    getTamano () {
      this.tamanos = []
      backedosic.get('/tamano').then(r => {
        this.tamanos = r.data
      })
    },
    getImpresion () {
      this.impresions = []
      backedosic.get('/impresion').then(r => {
        this.impresions = r.data
      })
    },
    getTipoForma () {
      this.tipoFormas = []
      backedosic.get('/tipoforma').then(r => {
        r.data.forEach(e => {
          this.tipoFormas.push({ idTipoForma: e.idTipoForma, nombreTipoForma: e.nombreTipoForma })
        })
      })
    },
    getUsuario () {
      this.usuarios = []
      backedosic.get('/usuario').then(r => {
        this.usuarios = r.data
      })
    },
    getEstados () {
      backedosic.get('/estado').then(r => {
        this.estados = r.data
      })
    },

    showStep (step) {
      this.step = step
    },

    editItem (item) {
      this.editedIndex = this.ordenes.indexOf(item)
      this.editedItem = Object.assign({}, item)

      this.dialog = true

      if (this.editedItem.nombreSellos && this.editedItem.nombreSellos.trim() !== '') {
        this.step = 3
      } else {
        this.step = 2
        this.getColPapels()
        this.getEspecformatinta(item)
      }
    },

    deleteItem (item) {
      this.editedIndex = this.ordenes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.ordenes.splice(this.editedIndex, 1)
      if (this.editedItem.idSellos) {
        backedosic.put(`/sellos/eliminar/${this.editedItem.idOrden}/${this.editedItem.idEspecificacionesForma}/${this.editedItem.idSellos}`).then(r => {
          console.log('Orden de sello Eliminado correctamente')
          this.ordenes = r.data.ordenes
          this.initialize()
          this.close()
        })
          .catch(error => {
            console.log('Error al eliminar la orden de sello:', error)
          })

        this.closeDelete()
      } else {
        backedosic.put(`/orden/eliminar/${this.editedItem.idOrden}/${this.editedItem.idEspecificacionesForma}`).then(r => {
          console.log('Orden Eliminado correctamente')
          this.ordenes = r.data.ordenes
          this.initialize()
          this.close()
        })
          .catch(error => {
            console.log('Error al eliminar la orden:', error)
          })

        this.closeDelete()
      }
    },

    close () {
      this.dialog = false
      this.step = 1
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemTintas = Object.assign({}, this.editedItemColPapelsAux)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async saveTintas (idEspecificacionesForma) {
      if (!this.editedItemTintas.idTinta || this.editedItemTintas.idTinta.length === 0) {
        console.log('No hay tintas seleccionadas.')
        return
      }
      const idTintasArray = Object.keys(this.editedItemTintas.idTinta).map(key => this.editedItemTintas.idTinta[key].idTinta)
      if (!Array.isArray(idTintasArray)) {
        console.error('idTintasArray no es un array.')
        return
      }
      if (this.editedIndex === -1) {
        console.log('no se edita')
        if (!idEspecificacionesForma || !idEspecificacionesForma[0]?.idEspecificacionesForma) {
          console.error('idEspecificacionesForma no es válido.')
          return
        }
        try {
          const response = await backedosic.post('/especforma-tinta', {
            idEspecificacionesForma: idEspecificacionesForma[0].idEspecificacionesForma,
            idTinta: idTintasArray
          })
          console.log('Tintas guardadas exitosamente:', response.data)
        } catch (error) {
          console.error('Error al guardar las tintas:', error.response?.data || error)
        }
      } else {
        if (!idEspecificacionesForma || typeof idEspecificacionesForma !== 'number') {
          console.error('idEspecificacionesForma no es válido.')
          return
        }
        try {
          const deleteResponse = await backedosic.delete(`/especforma-tinta/eliminar/${idEspecificacionesForma}`)
          if (deleteResponse.data.message === 'No había tintas para eliminar.') {
            console.log('No había tintas para eliminar.')
          } else {
            console.log('Tintas eliminadas exitosamente.')
          }

          if (idTintasArray.length > 0) {
            const response = await backedosic.post('/especforma-tinta', {
              idEspecificacionesForma: idEspecificacionesForma,
              idTinta: idTintasArray
            })
            console.log('Tintas guardadas exitosamente:', response.data)
          } else {
            console.log('No hay tintas seleccionadas para agregar.')
          }
        } catch (error) {
          console.error('Error al guardar las tintas:', error.response?.data || error)
        }
      }
    },

    async saveOrdImpresion () {
      if (isNaN(this.editedItem.idCliente?.idCliente)) {
        this.editedItem.nomCliente = this.editedItem.idCliente
        this.editedItem.idCliente = null
      } else {
        this.editedItem.idCliente = this.editedItem.idCliente.idCliente
        this.editedItem.nomCliente = null
      }
      if (isNaN(this.editedItem.idOrdenado?.idOrdenado)) {
        this.editedItem.nomOrdenado = this.editedItem.idOrdenado
        this.editedItem.idOrdenado = null
      } else {
        this.editedItem.idOrdenado = this.editedItem.idOrdenado.idOrdenado
        this.editedItem.nomOrdenado = null
      }
      this.editedItem.idEstado = 1

      if ((this.editedItem.idCliente || this.editedItem.nomCliente) &&
          (this.editedItem.idOrdenado || this.editedItem.nomOrdenado) &&
          this.editedItem.idForma && (!this.editedItem.telOrdenado ||
          this.editedItem.telOrdenado?.length === 10) &&
          this.editedItem.cantidad && this.editedItem.numTintas) {
        try {
          if (this.editedIndex > -1) {
            // Si es una actualización de orden existente
            const response = await backedosic.put(
              `/orden/actualizacion/${this.editedItem.idOrden}/${this.editedItem.idEspecificacionesForma}`,
              this.editedItem
            )
            this.ordenes = response.data.ordenes
            this.idEspecificacionesForma = response.data.idEspecificacionesForma
            this.saveTintas(this.editedItem.idEspecificacionesForma)
            console.log('Orden actualizada correctamente', this.ordenes)
          } else {
            // Si es una nueva orden
            this.editedItem.idUsuario = this.nombreUsuario.idUsuario
            if (this.editedItem.horaEntregaOrden) {
              this.editedItem.horaEntregaOrden = `${this.editedItem.horaEntregaOrden.hours.toString().padStart(2, '0')}:${this.editedItem.horaEntregaOrden.minutes.toString().padStart(2, '0')}`
            } else {
              this.editedItem.horaEntregaOrden = null
            }
            if (this.editedItem.fechaEntregaOrden) {
              this.editedItem.fechaEntregaOrden = `${this.editedItem.fechaEntregaOrden.getFullYear()}-${(this.editedItem.fechaEntregaOrden.getMonth() + 1).toString().padStart(2, '0')}-${this.editedItem.fechaEntregaOrden.getDate().toString().padStart(2, '0')}`
            } else {
              this.editedItem.fechaEntregaOrden = null
            }
            const response = await backedosic.post('/ordenEspecificaciones', this.editedItem)
            console.log('Orden creada correctamente')
            this.ordenes = response.data.ordenes
            this.idEspecificacionesForma = response.data.idEspecificacionesForma
            this.saveTintas(this.idEspecificacionesForma)
          }
          this.initialize()
          this.close()
        } catch (error) {
          console.log('Error al crear o actualizar la orden:', error)
        }
      } else {
        this.showErrorDialog = true
      }
    },
    async saveOrdSellos () {
      if (isNaN(this.editedItem.idCliente?.idCliente)) {
        this.editedItem.nomCliente = this.editedItem.idCliente
        this.editedItem.idCliente = null
      } else {
        this.editedItem.idCliente = this.editedItem.idCliente.idCliente
        this.editedItem.nomCliente = null
      }
      if (isNaN(this.editedItem.idOrdenado?.idOrdenado)) {
        this.editedItem.nomOrdenado = this.editedItem.idOrdenado
        this.editedItem.idOrdenado = null
      } else {
        this.editedItem.idOrdenado = this.editedItem.idOrdenado.idOrdenado
        this.editedItem.nomOrdenado = null
      }
      this.editedItem.idEstado = 1

      if ((this.editedItem.idCliente || this.editedItem.nomCliente) && (this.editedItem.idOrdenado || this.editedItem.nomOrdenado) &&
      (!this.editedItem.telOrdenado || this.editedItem.telOrdenado?.length === 10) && this.editedItem.cantidad && this.editedItem.tipo && this.editedItem.medida
      ) {
        try {
          if (this.editedIndex > -1) {
            backedosic.put(`/orden/actualizarOrdenSellos/${this.editedItem.idOrden}/${this.editedItem.idSellos}`, this.editedItem).then(r => {
              console.log('sello actualizado correctamente')
              this.ordenes = r.data.ordenes
              this.initialize()
              this.close()
            }).catch(error => {
              console.log('Error al actualizar la orden:', error)
            })
          } else {
            this.editedItem.idUsuario = this.nombreUsuario.idUsuario
            if (this.editedItem.horaEntregaOrden) {
              this.editedItem.horaEntregaOrden = `${this.editedItem.horaEntregaOrden.hours.toString().padStart(2, '0')}:${this.editedItem.horaEntregaOrden.minutes.toString().padStart(2, '0')}`
            } else {
              this.editedItem.horaEntregaOrden = null
            }
            if (this.editedItem.fechaEntregaOrden) {
              this.editedItem.fechaEntregaOrden = `${this.editedItem.fechaEntregaOrden.getFullYear()}-${(this.editedItem.fechaEntregaOrden.getMonth() + 1).toString().padStart(2, '0')}-${this.editedItem.fechaEntregaOrden.getDate().toString().padStart(2, '0')}`
            } else {
              this.editedItem.fechaEntregaOrden = null
            }
            backedosic.post('/ordenSellos', this.editedItem).then(r => {
              console.log('sello creada correctamente')
              this.ordenes = r.data.ordenes
              this.initialize()
              this.close()
            }).catch(error => {
              console.log('Error al crear la orden:', error)
            })
          }
          this.close()
        } catch (error) {
          console.log('Error al crear o actualizar la orden:', error)
        }
      } else {
        this.showErrorDialog = true
      }
    },

    getColor (urgente) {
      if (urgente === 'Urgente') return 'red'
      else if (urgente === 'No urgente') return 'green'
    },

    getEstado (idEstado) {
      if (idEstado === 'Proceso') return 'blue'
      else if (idEstado === 'Diseño') return 'teal'
      else if (idEstado === 'Bodega') return 'indigo'
      else if (idEstado === 'Terminado') return 'orange'
      else if (idEstado === 'Entregado') return 'gray'
    },

    isExpanded (item) {
      return this.expandedItems.includes(item)
    },
    toggleExpansion (item) {
      if (this.isExpanded(item)) {
        this.expandedItems = this.expandedItems.filter(expandedItem => expandedItem !== item)
        backedosic.put(`/orden/changeEstado/${this.editedItem.OrdenID}/${this.editedItem.idEstado}`)
          .then(response => {
            console.log('Estado actualizado en la base de datos:', response.data)
          })
          .catch(error => {
            console.error('Error al actualizar el estado en la base de datos:', error)
          })
      } else {
        this.expandedItems.push(item)
      }
    },
    toggleSelect (item) {
      item.showSelect = !item.showSelect
    },
    handleOptionChange (item) {
      backedosic.put(`/orden/changeEstado/${item.idOrden}/${item.nomEstado}`)
        .then(response => {
          console.log('Estado actualizado en la base de datos:', response.data)
          this.initialize()
        })
        .catch(error => {
          console.error('Error al actualizar el estado en la base de datos:', error)
        })
      item.showSelect = false
      console.log('estado', item.nomEstado)
      if (item.nomEstado === 5) {
        console.log('entro')
        this.generaOrdEntrega(item)
      }
    },
    getOrdenadobyID () {
      if (this.editedItem && this.editedItem.idOrdenado) {
        if (this.editedItem.idOrdenado.idOrdenado) {
          backedosic.get(`/ordenado/${this.editedItem.idOrdenado.idOrdenado}`).then(r => {
            this.ordenadoTel = r.data
            if (this.ordenadoTel && this.ordenadoTel.data) {
              this.editedItem.telOrdenado = this.ordenadoTel.data.telOrdenado
            } else {
              this.editedItem.telOrdenado = ''
            }
          })
        } else {
          this.editedItem.telOrdenado = ''
        }
      } else {
        console.log('')
      }
    },
    fetchTelOrdenado: function () {
      this.getOrdenadobyID()
    },
    async getOrdenadosPorCliente (idCliente) {
      if (this.ordenados.length === 0) {
        this.loadingOrdenados = true
        try {
          const response = await backedosic.get(`/ordenado/ordxcliente/${idCliente}`)
          response.data.forEach(e => {
            this.ordenados.push({ idOrdenado: e.idOrdenado, nomOrdenado: e.nomOrdenado, telOrdenado: e.telOrdenado })
          })
        } catch (error) {
          console.error('Error al obtener los clientes:', error)
        } finally {
          this.loadingOrdenados = false
        }
      }
    },
    onChageCliente () {
      this.ordenados = []
      this.editedItem.idOrdenado = ''
      this.editedItem.telOrdenado = ''
    },
    filtrar (value, query) {
      if (query === 'urgente' || query === 'Urgente') {
        return value != null &&
           query != null &&
           typeof value === 'string' &&
           value.toLowerCase() === query.toLowerCase()
      } else {
        return value != null &&
           query != null &&
           typeof value === 'string' &&
           value.toString().toUpperCase().indexOf(query.toUpperCase()) !== -1
      }
    },
    addColPapels () {
      this.editedItemColPapels.idEspecificacionesForma = String(this.nombreUsuario.idUsuario)
      backedosic.post('/colpapel', this.editedItemColPapels)
        .then(response => {
          console.log('colsPapels creadas correctamente', response.data.colsPapels)
          this.resetTempColPapels()
          this.getColPapels()
        })
        .catch(error => {
          console.error('Error al crear colsPapels:', error.response ? error.response.data : error.message)
        })
    },

    resetTempColPapels () {
      this.colPapels.length = 0
      this.colPapels = []
      this.editedItemColPapels = { colorPapel: '', kgs: '', marginales: '', idColPapel: null }
    },
    deleteItemColsPapels (item) {
      this.editedIndexAux = this.colPapels.indexOf(item)
      this.editedItemColPapelsAux = Object.assign({}, item)
      backedosic.put(`/colpapel/eliminar/${this.editedItemColPapelsAux.idColPapel}`)
        .then(r => {
          console.log('ColPapel eliminado correctamente')
          this.colPapels = r.data.colPapels
          this.getColPapels()
        })
        .catch(error => {
          console.log('Error al eliminar el colPapel:', error)
        })
    },
    // Abrir el diálogo de observaciones
    openObservacionesDialog (item) {
      this.editedItem = { ...item } // Copiar los datos del ítem seleccionado
      this.observacionesDialog = true // Mostrar el diálogo
    },
    // Cerrar el diálogo de observaciones
    closeObservacionesDialog () {
      this.observacionesDialog = false // Ocultar el diálogo
      this.editedItem = Object.assign({}, this.defaultItem)
    },
    // Guardar las observaciones
    async saveObservaciones () {
      try {
        await backedosic.put(
        `/orden/${this.editedItem.idOrden}/observaciones-cliente`,
        {
          observacionesCliente: this.editedItem.observacionesCliente.trim() // Enviar como objeto JSON
        }
        ).catch(error => {
          console.log('Error al actualizar las observaciones:', error)
        })
        this.initialize()
        this.closeObservacionesDialog()
      } catch (error) {
        console.error('"Error al guardar las observaciones:"')
      }
    },

    async generatePDF (item) {
      try {
        this.editedIndex = this.ordenes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        // eslint-disable-next-line new-cap
        const doc = new jsPDF({
          orientation: 'vertical', unit: 'cm', format: 'letter'
        })
        // Separar fecha y hora
        const [fecha, hora] = this.editedItem.fechaHoraOrden.split(' ')

        // Añadir la imagen en la esquina superior derecha
        const imageUrl = require('@/assets/CG3.webp') // Reemplaza con la ruta de tu imagen
        doc.addImage(imageUrl, 'WEBP', 0.5, 0.7, 4, 2.5)
        doc.setFont('Times', 'bold')
        // Establecer el título del PDF
        doc.setFontSize(12)
        doc.text('Imprenta Cordero, Graficos CG3', 5.5, 1)
        doc.setFontSize(10)
        doc.text('ORDEN DE IMPRESION', 6.5, 1.4)

        doc.setLineWidth(0.01)

        // Fecha
        doc.setFillColor(200, 200, 200)
        doc.roundedRect(13.1, 0.7, 4, 0.4, 0.1, 0.1, 'FD')
        doc.setFont('Times', 'bold')
        doc.setFontSize(10)
        doc.text('FECHA', 14.5, 1)
        doc.roundedRect(13.1, 1.1, 4, 1, 0.1, 0.1)
        doc.setFont('Helvetica', 'normal')
        doc.setFontSize(9)
        doc.text(`${fecha}`, 14.3, 1.7)
        doc.setFont('Times', 'normal')

        // Folio
        doc.setFillColor(200, 200, 200)
        doc.roundedRect(17.1, 0.7, 4, 0.4, 0.1, 0.1, 'FD')
        doc.setFont('Times', 'bold')
        doc.setFontSize(10)
        doc.text('FOLIO', 18.5, 1)
        doc.roundedRect(17.1, 1.1, 4, 1, 0.1, 0.1)
        doc.setFont('Helvetica', 'normal')
        doc.setFontSize(14)
        doc.text(this.editedItem.folio, 18.2, 1.8)
        doc.setFont('Times', 'normal')

        // Hora
        doc.setFillColor(200, 200, 200)
        doc.roundedRect(13.1, 2.2, 1.2, 0.7, 0.1, 0.1, 'FD')
        doc.setFont('Times', 'bold')
        doc.setFontSize(9)
        doc.text('HORA', 13.24, 2.7)
        doc.roundedRect(14.3, 2.2, 2.8, 0.7, 0.1, 0.1)
        doc.setFont('Helvetica', 'normal')
        doc.setFontSize(9)
        doc.text(`${hora}`, 14.8, 2.7)
        doc.setFont('Times', 'normal')

        // Vendedor
        doc.setFillColor(200, 200, 200)
        doc.roundedRect(17.1, 2.2, 2, 0.7, 0.1, 0.1, 'FD')
        doc.setFont('Times', 'bold')
        doc.setFontSize(9)
        doc.text('VENDEDOR', 17.2, 2.7)
        doc.roundedRect(19.1, 2.2, 2, 0.7, 0.1, 0.1)
        doc.setFont('Helvetica', 'normal')
        doc.setFontSize(9)
        doc.text(this.editedItem.nomUsuario, 19.4, 2.7)
        doc.setFont('Times', 'normal')

        let y = 4

        doc.setFillColor(200, 200, 200)
        doc.roundedRect(0.5, 3.5, 4, 2.3, 0.1, 0.1, 'FD')
        doc.roundedRect(4.5, 3.5, 16.6, 2.3, 0.1, 0.1)
        doc.setFont('Times', 'bold')
        doc.setFontSize(9)

        const infoTitleOrden = [
          { label: 'Cliente: ', value: this.editedItem.nombreCliente },
          { label: 'Ordenado por: ', value: this.editedItem.nombreOrdenado },
          { label: 'Nombre de la forma: ', value: this.editedItem.nombreForma },
          { label: 'Entrega a domicilio: ', value: this.editedItem.aDomicilio }
        ]
        // Iterar sobre los elementos y escribir en el PDF
        infoTitleOrden.forEach(item => {
          if (item.label && typeof item.label === 'string') { // Verificar que el label sea una cadena
            doc.text(item.label, 0.7, y) // Escribir el label en 0.7 cm
          }

          if (item.value != null) { // Verificar que el valor no sea nulo o indefinido
            doc.setFont('Times', 'normal')
            // Mostrar 'sí' solo para "Entrega a domicilio"
            if (item.label.includes('Entrega a domicilio')) {
              if (item.value === 1) {
                item.value = 'sí' // Cambiar a 'sí' si el valor es 1
              } else if (item.value === 0) {
                item.value = '' // Dejar vacío si el valor es 0
              }
            }
            doc.text(String(item.value), 5, y) // Asegurarse de convertir el valor a cadena
            doc.setFont('Times', 'bold') // Volver a la fuente en negrita para el siguiente label
          }
          y += 0.5 // Incrementar la posición Y para la siguiente línea
        })

        doc.setFillColor(200, 200, 200)
        doc.rect(14, 4, 1, 0.5, 'FD')
        doc.text('Tel:', 14.3, 4.4)
        doc.setFont('Times', 'normal')
        if (this.editedItem.telOrdenado) {
          doc.text(this.editedItem.telOrdenado, 15.2, 4.4)
        }

        doc.setFillColor(200, 200, 200)
        doc.rect(11, 4.5, 2, 0.5, 'FD')
        doc.setFont('Times', 'bold')
        doc.text('Dia entrega:', 11.2, 4.88)
        doc.setFont('Times', 'normal')
        if (this.editedItem.fechaEntregaOrden) {
          doc.text(this.editedItem.fechaEntregaOrden, 13.2, 4.88)
        }

        doc.setFillColor(200, 200, 200)
        doc.rect(17, 4.5, 1, 0.5, 'FD')
        doc.setFont('Times', 'bold')
        doc.text('Hora:', 17.1, 4.88)
        doc.setFont('Times', 'normal')
        if (this.editedItem.horaEntregaOrden) {
          doc.text(this.editedItem.horaEntregaOrden, 18.2, 4.88)
        }

        doc.setFillColor(200, 200, 200)
        doc.rect(12.5, 5.2, 2, 0.5, 'FD')
        doc.setFont('Times', 'bold')
        doc.text('Recogen:', 12.7, 5.55)
        doc.setFont('Times', 'normal')
        if (this.editedItem.aDomicilio === 0) {
          this.editedItem.aDomicilio = 'sí'
          doc.text(this.editedItem.aDomicilio, 14.6, 5.55)
        } else {
          this.editedItem.aDomicilio = ' '
          doc.text(this.editedItem.aDomicilio, 14.6, 5.55)
        }
        doc.setFillColor(200, 200, 200)
        doc.roundedRect(0.5, 6, 20.6, 2.8, 0.1, 0.1, 'FD')
        doc.setFont('Times', 'bold')
        doc.text('Cantidad: ', 0.7, 6.5)
        doc.text('Papel: ', 0.7, 7)
        doc.text('Hacer diseño: ', 0.7, 7.5)
        doc.text('Impresión: ', 0.7, 8)
        doc.text('Folios: ', 0.7, 8.5)
        doc.text('Tintas: ', 5.2, 6.5)
        doc.text('Selección de color: ', 17.4, 6.5)
        doc.text('Bond: ', 3, 7)
        doc.text('Autocopiante: ', 6.2, 7)
        doc.text('Couché: ', 10, 7)
        doc.text('Especial: ', 12.8, 7)
        doc.text('Tamaño: ', 16.8, 7)
        doc.text('Según muestra: ', 7, 7.5)
        doc.text('Con cambios: ', 12, 7.5)
        doc.text('Sin cambios: ', 16, 7.5)
        doc.text('Solo Frente: ', 2.8, 8)
        doc.text('Ambos lados: ', 5.9, 8)
        doc.text('Forma Normal: ', 9.7, 8)
        doc.text('Forma Continua: ', 13.2, 8)
        doc.text('GTO: ', 16.9, 8)
        doc.text('Kord: ', 19, 8)
        doc.text('del ', 3, 8.5)
        doc.text(' al ', 6, 8.5)
        doc.text('Serie: ', 11, 8.5)
        doc.text('Blocks de: ', 16, 8.5)

        doc.setFillColor(255, 255, 255)
        doc.rect(2.3, 6.18, 2.5, 0.4, 'FD')
        doc.rect(6.3, 6.18, 1, 0.4, 'FD')
        doc.rect(7.4, 6.18, 1.8, 0.4, 'FD')
        doc.rect(9.2, 6.18, 1.8, 0.4, 'FD')
        doc.rect(11, 6.18, 1.8, 0.4, 'FD')
        doc.rect(12.8, 6.18, 1.8, 0.4, 'FD')
        doc.rect(14.6, 6.18, 1.8, 0.4, 'FD')
        doc.rect(20, 6.18, 1, 0.4, 'FD')
        doc.rect(4, 6.68, 1, 0.4, 'FD')
        doc.rect(8.2, 6.68, 1, 0.4, 'FD')
        doc.rect(11.2, 6.68, 1, 0.4, 'FD')
        doc.rect(14.1, 6.68, 2.3, 0.4, 'FD')
        doc.rect(18.1, 6.68, 2.88, 0.4, 'FD')
        doc.rect(2.7, 7.18, 1, 0.4, 'FD')
        doc.rect(9.2, 7.18, 1, 0.4, 'FD')
        doc.rect(13.9, 7.18, 1, 0.4, 'FD')
        doc.rect(17.8, 7.18, 1, 0.4, 'FD')
        doc.rect(4.6, 7.68, 1, 0.4, 'FD')
        doc.rect(7.89, 7.68, 1, 0.4, 'FD')
        doc.rect(11.99, 7.68, 1, 0.4, 'FD')
        doc.rect(15.7, 7.68, 1, 0.4, 'FD')
        doc.rect(17.8, 7.68, 1, 0.4, 'FD')
        doc.rect(19.9, 7.68, 1, 0.4, 'FD')
        doc.rect(3.6, 8.18, 2.2, 0.4, 'FD')
        doc.rect(6.5, 8.18, 2.2, 0.4, 'FD')
        doc.rect(12, 8.18, 2.2, 0.4, 'FD')
        doc.rect(17.5, 8.18, 2.2, 0.4, 'FD')

        doc.setFont('Times', 'normal')
        if (this.editedItem.cantidad != null) {
          doc.text(String(this.editedItem.cantidad), 2.6, 6.5)
        }
        if (this.editedItem.numTintas != null) {
          doc.text(String(this.editedItem.numTintas), 6.5, 6.5)
        }
        if (this.editedItem.selColor != null) {
          if (this.editedItem.selColor === 1) {
            this.editedItem.selColor = 'sí' // Cambiar a 'sí' si el valor es 1
          }
          doc.text(String(this.editedItem.selColor), 20.4, 6.5)
        }
        if (this.editedItem.nombrePapel != null) {
          if (this.editedItem.nombrePapel === 'BOND') {
            doc.text('sí', 4.4, 7)
          } else if (this.editedItem.nombrePapel === 'AUTOCOPIANTE') {
            doc.text('sí', 8.6, 7)
          } else if (this.editedItem.nombrePapel === 'COUCHE') {
            doc.text('sí', 11.6, 7)
          } else {
            doc.text(String(this.editedItem.nombrePapel), 14.2, 7)
          }
        }
        if (this.editedItem.nombreTamano != null) {
          doc.text(String(this.editedItem.nombreTamano), 18.2, 7)
        }
        if (this.editedItem.diseno != null) {
          if (this.editedItem.diseno === 1) {
            this.editedItem.diseno = 'sí' // Cambiar a 'sí' si el valor es 1
          }
          doc.text(String(this.editedItem.diseno), 3.1, 7.5)
        }
        if (this.editedItem.muestra != null) {
          if (this.editedItem.muestra === 1) {
            this.editedItem.muestra = 'sí' // Cambiar a 'sí' si el valor es 1
          }
          doc.text(String(this.editedItem.muestra), 9.5, 7.5)
        }
        if (this.editedItem.cambios != null) {
          if (this.editedItem.cambios === 1) {
            this.editedItem.cambios = 'sí' // Cambiar a 'sí' si el valor es 1
            doc.text(String(this.editedItem.cambios), 14.2, 7.5)
          }
        } else if (this.editedItem.cambios === null) {
          this.editedItem.cambios = 'sí'
          doc.text(String(this.editedItem.cambios), 18.2, 7.5)
        }
        if (this.editedItem.TipoImpresion === 'SOLO FRENTE') {
          doc.text('sí', 5, 8)
        }
        if (this.editedItem.TipoImpresion === 'AMBOS LADOS') {
          doc.text('sí', 8.3, 8)
        }
        if (this.editedItem.nombreTipoForma === 'NORMAL') {
          doc.text('sí', 12.4, 8)
        }
        if (this.editedItem.nombreTipoForma === 'CONTINUA') {
          doc.text('sí', 16.1, 8)
        }
        if (this.editedItem.nombreTipoForma === 'GTO') {
          doc.text('sí', 18.2, 8)
        }
        if (this.editedItem.nombreTipoForma === 'KORD') {
          doc.text('sí', 20.3, 8)
        }
        if (this.editedItem.folioIn != null) {
          doc.text(String(this.editedItem.folioIn), 4, 8.5)
        }
        if (this.editedItem.folioFin != null) {
          doc.text(String(this.editedItem.folioFin), 6.9, 8.5)
        }
        if (this.editedItem.serie != null) {
          doc.text(String(this.editedItem.serie), 12.4, 8.5)
        }
        if (this.editedItem.blocks != null) {
          doc.text(String(this.editedItem.blocks), 17.9, 8.5)
        }

        doc.setFillColor(200, 200, 200)
        doc.roundedRect(0.5, 9, 11.5, 0.5, 0.1, 0.1, 'FD')
        doc.setFillColor(255, 255, 255)
        doc.roundedRect(0.5, 9.5, 11.5, 3, 0.1, 0.1, 'FD')
        doc.line(1.5, 9, 1.5, 12.5)
        doc.line(6, 9, 6, 12.5)
        doc.line(7.5, 9, 7.5, 12.5)

        doc.setFont('Times', 'bold')
        doc.text('No.', 0.7, 9.35)
        doc.text('COLOR DE PAPEL', 2, 9.35)
        doc.text('KGS.', 6.5, 9.35)
        doc.text('MARGINALES', 8.5, 9.35)

        doc.setFillColor(200, 200, 200)
        doc.roundedRect(12.2, 9, 8.9, 3.5, 0.1, 0.1, 'FD')
        doc.setFont('Times', 'bold')
        doc.text('SELLOS', 16.2, 9.35)
        doc.text('Madera: ', 12.5, 9.8)
        doc.text('Autoentintable: ', 12.5, 10.5)
        doc.text('Fechador: ', 12.5, 11.2)
        doc.text('Otro: ', 12.5, 11.9)

        // Iteramos sobre el array colPapels y escribimos los datos
        doc.setFont('Times', 'normal')

        if (item) {
          // Asegúrate de que colPapels no sea nulo, esté vacío y sea un array
          y = 9.9
          let num = 1
          try {
            const colPapels = JSON.parse(item.colPapels || '[]')
            // Verificamos que colPapels sea un array válido
            if (Array.isArray(colPapels) && colPapels.length > 0) {
              // Ahora puedes recorrer el array de colPapels
              colPapels.forEach(colPapel => {
                // Lógica para agregar los detalles al PDF (como se mostró anteriormente)
                doc.text(num.toString(), 1, y)
                doc.text(colPapel.colorPapel || '', 1.8, y) // Ajusta las coordenadas
                doc.text(colPapel.kgs || '', 6.2, y)
                doc.text(colPapel.marginales || '', 7.8, y)
                y += 0.4
                num += 1
              })
            } else {
              console.log('No hay colPapels para esta orden.')
            }
          } catch (error) {
            console.error('"Error al obtener colPapels o generar PDF:"', error)
          }
          try {
            y = 6.5
            await this.getEspecformatinta(item)

            const tintas = this.editedItemTintas.idTinta || []
            if (Array.isArray(tintas) && tintas.length > 0) {
              let x = 7.5

              tintas.forEach((tinta, index) => {
                const nombreTinta = tinta.nombreTinta || ''

                doc.text(nombreTinta, x, y)

                x += 2.0
              })

              y += 0.5
            } else {
              console.log('No hay tintas para esta orden.')
            }
          } catch (error) {
            console.log('Error al obtener las tintas o generar PDF:', error)
          }
        } else {
          console.log('No hay orden seleccionada')
        }

        doc.text('Puño: ', 14.5, 9.8)
        doc.text('Moldura: ', 16.2, 9.8)
        doc.text('Medida: ', 18.3, 9.8)
        doc.text('Medida: ', 18.3, 10.5)
        doc.text('Autoentintable: ', 15, 11.2)
        doc.text('Normal: ', 18.3, 11.2)
        doc.text('Medida: ', 17.2, 11.9)

        doc.setFillColor(255, 255, 255)
        doc.rect(13.8, 9.5, 0.5, 0.4, 'FD')
        doc.rect(15.4, 9.5, 0.5, 0.4, 'FD')
        doc.rect(17.6, 9.5, 0.5, 0.4, 'FD')
        doc.rect(19.55, 9.5, 1.4, 0.4, 'FD')

        doc.rect(14.7, 10.2, 0.5, 0.4, 'FD')
        doc.rect(19.55, 10.2, 1.4, 0.4, 'FD')

        doc.rect(14, 10.9, 0.5, 0.4, 'FD')
        doc.rect(17.2, 10.9, 0.5, 0.4, 'FD')
        doc.rect(19.55, 10.9, 1.4, 0.4, 'FD')

        doc.rect(13.35, 11.6, 3.5, 0.4, 'FD')
        doc.rect(18.45, 11.6, 2.5, 0.4, 'FD')

        doc.setFont('Times', 'normal')
        doc.setFontSize(9)
        if (this.editedItem.nombreSellos != null) {
          if (this.editedItem.nombreSellos.toLowerCase() === 'madera'.toLowerCase()) {
            doc.text('sí', 13.9, 9.8)
            doc.text(this.editedItem.medida, 19.8, 9.8)
          }
          if (this.editedItem.nombreSellos.toLowerCase() === 'autoentintable'.toLowerCase()) {
            doc.text('sí', 14.8, 10.5)
            doc.text(this.editedItem.medida, 19.8, 10.5)
          }
          if (this.editedItem.nombreSellos.toLowerCase() === 'fechador'.toLowerCase()) {
            doc.text('sí', 14.15, 11.2)
          }
          if (this.editedItem.nombreSellos.toLowerCase() !== 'autoentintable'.toLowerCase() &&
          this.editedItem.nombreSellos.toLowerCase() !== 'fechador'.toLowerCase() &&
          this.editedItem.nombreSellos.toLowerCase() !== 'madera'.toLowerCase()) {
            this.editedItem.nombreSellos = this.editedItem.nombreSellos + ' ' + this.editedItem.tipo
            doc.text(this.editedItem.nombreSellos, 13.5, 11.9)
            doc.text(this.editedItem.medida, 18.7, 11.9)
          }
        }
        if (this.editedItem.tipo !== null) {
          if (this.editedItem.tipo.toLowerCase() === 'puño'.toLowerCase()) {
            doc.text('sí', 15.55, 9.8)
          }
          if (this.editedItem.tipo.toLowerCase() === 'moldura'.toLowerCase()) {
            doc.text('sí', 17.75, 9.8)
          }
          if (this.editedItem.tipo.toLowerCase() === 'autoentintable'.toLowerCase()) {
            doc.text('sí', 17.35, 11.2)
          }
          if (this.editedItem.tipo.toLowerCase() === 'normal'.toLowerCase()) {
            doc.text('sí', 20.1, 11.2)
          }
        }

        doc.roundedRect(0.5, 12.8, 20.6, 12.9, 0.1, 0.1)
        doc.setFont('Times', 'bold')
        doc.setFontSize(10)
        doc.text('OBSERVACIONES', 9, 13.4)

        if (this.editedItem.observacionesOrden != null) {
          doc.setFontSize(10)
          doc.setFont('Times', 'normal')
          doc.text(this.editedItem.observacionesOrden, 1, 14)
        }

        doc.roundedRect(0.5, 26, 3.84, 1.5, 0.1, 0.1)
        doc.roundedRect(4.67, 26, 3.84, 1.5, 0.1, 0.1)
        doc.roundedRect(8.81, 26, 3.84, 1.5, 0.1, 0.1)
        doc.roundedRect(12.95, 26, 3.84, 1.5, 0.1, 0.1)
        doc.roundedRect(17.25, 26, 3.84, 1.5, 0.1, 0.1)
        doc.setFont('Times', 'bold')
        doc.text('ELABORO', 1.6, 27.4)
        doc.text('DISEÑO', 5.9, 27.4)
        doc.text('REVISO', 10.2, 27.4)
        doc.text('IMPRIMIO', 14.1, 27.4)
        doc.text('MAQUILA DE: ', 18.1, 27.4)

        if (this.editedItem.nomUsuario != null) {
          doc.setFontSize(12)
          doc.setFont('Times', 'normal')
          doc.text(this.editedItem.nomUsuario, 1.6, 26.7)
        }

        doc.save('orden.pdf')
        this.editedItem = { ...this.defaultItem }
        this.editedItemTintas = { ...this.editedItemTintasAux }
        this.editedIndex = -1
      } catch (error) {
        console.error('"Error al obtener colPapels o generar PDF:"', error)
      }
    },
    async generaOrdEntrega (item) {
      try {
        this.editedIndex = this.ordenes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        // eslint-disable-next-line new-cap
        const doc = new jsPDF({
          orientation: 'vertical', unit: 'cm', format: 'letter'
        })
        // Separar fecha y hora
        const fechaHoraActual = new Date()
        // Formatear la fecha y hora (puedes personalizar el formato)
        const fecha = fechaHoraActual.toLocaleDateString() // Formato de fecha local
        const hora = fechaHoraActual.toLocaleTimeString() // Formato de hora local

        // Añadir la imagen en la esquina superior derecha
        const imageUrl = require('@/assets/CG3.webp') // Reemplaza con la ruta de tu imagen
        doc.addImage(imageUrl, 'WEBP', 0.5, 0.7, 4, 2.5)
        doc.setFont('Times', 'bold')
        // Establecer el título del PDF
        doc.setFontSize(12)
        doc.text('Imprenta Cordero, Graficos CG3', 5.5, 1)
        doc.setFontSize(10)
        doc.text('ORDEN DE ENTREGA', 6.5, 1.4)

        doc.setLineWidth(0.01)

        // Fecha
        doc.setFillColor(200, 200, 200)
        doc.roundedRect(13.1, 0.7, 4, 0.4, 0.1, 0.1, 'FD')
        doc.setFont('Times', 'bold')
        doc.setFontSize(10)
        doc.text('FECHA', 14.5, 1)
        doc.roundedRect(13.1, 1.1, 4, 1, 0.1, 0.1)
        doc.setFont('Helvetica', 'normal')
        doc.setFontSize(9)
        doc.text(`${fecha}`, 14.3, 1.7)
        doc.setFont('Times', 'normal')

        // Folio
        doc.setFillColor(200, 200, 200)
        doc.roundedRect(17.1, 0.7, 4, 0.4, 0.1, 0.1, 'FD')
        doc.setFont('Times', 'bold')
        doc.setFontSize(10)
        doc.text('FOLIO', 18.5, 1)
        doc.roundedRect(17.1, 1.1, 4, 1, 0.1, 0.1)
        doc.setFont('Helvetica', 'normal')
        doc.setFontSize(14)
        doc.text(this.editedItem.folio, 18.2, 1.8)
        doc.setFont('Times', 'normal')

        // Hora
        doc.setFillColor(200, 200, 200)
        doc.roundedRect(13.1, 2.2, 1.2, 0.7, 0.1, 0.1, 'FD')
        doc.setFont('Times', 'bold')
        doc.setFontSize(9)
        doc.text('HORA', 13.24, 2.7)
        doc.roundedRect(14.3, 2.2, 2.8, 0.7, 0.1, 0.1)
        doc.setFont('Helvetica', 'normal')
        doc.setFontSize(9)
        doc.text(`${hora}`, 14.8, 2.7)
        doc.setFont('Times', 'normal')

        // Vendedor
        doc.setFillColor(200, 200, 200)
        doc.roundedRect(17.1, 2.2, 2, 0.7, 0.1, 0.1, 'FD')
        doc.setFont('Times', 'bold')
        doc.setFontSize(9)
        doc.text('VENDEDOR', 17.2, 2.7)
        doc.roundedRect(19.1, 2.2, 2, 0.7, 0.1, 0.1)
        doc.setFont('Helvetica', 'normal')
        doc.setFontSize(9)
        doc.text(this.editedItem.nomUsuario, 19.4, 2.7)
        doc.setFont('Times', 'normal')

        let y = 4.4

        doc.setFont('Times', 'bold')
        doc.text('DATOS CLIENTE', 0.5, 3.9)
        doc.setFillColor(200, 200, 200)
        doc.roundedRect(0.5, 4, 4, 1.2, 0.1, 0.1, 'FD')
        doc.roundedRect(4.5, 4, 16.6, 1.2, 0.1, 0.1)
        doc.setFontSize(9)

        const infoTitleOrden = [
          { label: 'Cliente: ', value: this.editedItem.nombreCliente },
          { label: 'Ordenado por: ', value: this.editedItem.nombreOrdenado }
        ]
        // Iterar sobre los elementos y escribir en el PDF
        infoTitleOrden.forEach(item => {
          if (item.label && typeof item.label === 'string') { // Verificar que el label sea una cadena
            doc.text(item.label, 0.7, y) // Escribir el label en 0.7 cm
          }

          if (item.value != null) { // Verificar que el valor no sea nulo o indefinido
            doc.setFont('Times', 'normal')
            doc.text(String(item.value), 5, y) // Asegurarse de convertir el valor a cadena
            doc.setFont('Times', 'bold') // Volver a la fuente en negrita para el siguiente label
          }
          y += 0.5 // Incrementar la posición Y para la siguiente línea
        })
        doc.text('Telefono:', 14.3, 4.9)
        doc.setFont('Times', 'normal')
        if (this.editedItem.telOrdenado) {
          doc.text(this.editedItem.telOrdenado, 15.7, 4.9)
        }

        let textoCorrido = ''

        if (this.editedItem.cantidad != null) {
          textoCorrido += this.editedItem.cantidad + ' '
        }

        if (this.editedItem.nombreForma != null) {
          textoCorrido += this.editedItem.nombreForma + ' '
        }

        if (this.editedItem.nombreTamano != null) {
          textoCorrido += this.editedItem.nombreTamano + ', '
        }

        if (this.editedItem.numTintas != null) {
          textoCorrido += 'A '
          textoCorrido += this.editedItem.numTintas + ' TINTAS '
        }

        try {
          await this.getEspecformatinta(item) // Obtener las tintas

          const tintas = this.editedItemTintas.idTinta || [] // Obtener la lista de tintas

          // Verificar si hay tintas
          if (Array.isArray(tintas) && tintas.length > 0) {
            // Construir la cadena de tintas
            let tintasTexto = '('

            tintas.forEach((tinta, index) => {
              const nombreTinta = tinta.nombreTinta || ''
              tintasTexto += nombreTinta

              // Agregar una coma si no es la última tinta
              if (index < tintas.length - 1) {
                tintasTexto += ', '
              }
            })

            tintasTexto += ')' // Cerrar el paréntesis

            // Agregar el número de tintas y las tintas entre paréntesis
            if (this.editedItem.numTintas != null) {
              textoCorrido += tintasTexto + ' '
            }

            y += 0.5 // Ajustar la coordenada Y para el siguiente elemento
          } else {
            console.log('No hay tintas para esta orden.')
          }
        } catch (error) {
          console.log('Error al obtener las tintas o generar PDF:', error)
        }
        if (this.editedItem.nombrePapel != null) {
          textoCorrido += 'EN PAPEL '
          textoCorrido += this.editedItem.nombrePapel + ' '
        }

        if (this.editedItem.TipoImpresion != null) {
          textoCorrido += 'IMPRESO '
          textoCorrido += this.editedItem.TipoImpresion + ' '
        }

        if (this.editedItem.folioIn != null && this.editedItem.folioFin != null) {
          textoCorrido += 'CON FOLIOS DEL ' + this.editedItem.folioIn + ' AL ' + this.editedItem.folioFin + ' '
        }

        if (this.editedItem.serie != null) {
          textoCorrido += 'CON SERIE ' + this.editedItem.serie + ' '
        }

        if (this.editedItem.blocks != null) {
          textoCorrido += 'EN BLOCKS ' + this.editedItem.blocks + '. '
        }

        if (this.editedItem.nombreSellos != null) {
          textoCorrido += this.editedItem.nombreSellos + ' '
        }

        if (this.editedItem.tipo != null) {
          textoCorrido += this.editedItem.tipo + ' '
        }

        if (this.editedItem.medida != null) {
          textoCorrido += this.editedItem.medida + '. '
        }

        if (this.editedItem.nombreForma.toUpperCase() === 'SELLOS' || this.editedItem.nombreForma.toUpperCase() === 'SELLO') {
          // Especificaciones Orden (Lineas)
          doc.setFont('Times', 'bold')
          doc.setFontSize(9)
          doc.text('ESPECIFICACIONES DE ORDEN', 0.5, 5.8)
          doc.setFillColor(200, 200, 200)
          doc.roundedRect(0.5, 5.9, 20.6, 3, 0.1, 0.1)
          doc.setFont('Times', 'normal')

          // Observaciones (Lineas)
          doc.setFillColor(200, 200, 200)
          doc.roundedRect(0.5, 9.3, 20.6, 0.5, 0.1, 0.1, 'FD')
          doc.setFillColor(255, 255, 255)
          doc.roundedRect(0.5, 9.8, 20.6, 2.5, 0.1, 0.1, 'FD')
          doc.setFont('Times', 'bold')
          doc.setFontSize(10)
          doc.text('OBSERVACIONES', 9, 9.7)

          if (this.editedItem.observacionesCliente != null) {
            doc.setFontSize(10)
            doc.setFont('Times', 'normal')
            doc.text(this.editedItem.observacionesCliente, 0.8, 10.2)
          }
          doc.setFont('Times', 'normal')
          // Salto de linea para especificaciones de la orden
          // Definir el ancho máximo de la línea (en unidades de jsPDF)
          const maxWidth = 20 // Ajusta este valor según el ancho de tu hoja

          // Dividir el texto en líneas que caben dentro del ancho máximo
          const lines = doc.splitTextToSize(textoCorrido.toUpperCase(), maxWidth)

          // Definir las coordenadas iniciales
          const x = 0.7 // Coordenada X inicial
          y = 6.4 // Coordenada Y inicial

          // Agregar cada línea al PDF
          lines.forEach((line) => {
            doc.text(line, x, y) // Agregar la línea al PDF
            y += 0.4 // Ajustar la coordenada Y para la siguiente línea (ajusta el espaciado según sea necesario)
          })
        } else {
          // Especificaciones Orden (Lineas)
          doc.setFont('Times', 'bold')
          doc.setFontSize(9)
          doc.text('ESPECIFICACIONES DE ORDEN', 0.5, 5.9)
          doc.setFillColor(200, 200, 200)
          doc.roundedRect(0.5, 6, 13.2, 3, 0.1, 0.1)

          // Papel (Lineas)
          doc.setFillColor(200, 200, 200)
          doc.roundedRect(14.1, 5.5, 7, 0.5, 0.1, 0.1, 'FD')
          doc.setFillColor(255, 255, 255)
          doc.roundedRect(14.1, 6, 7, 3, 0.1, 0.1, 'FD')
          doc.line(15.1, 5.5, 15.1, 9)
          doc.line(19.6, 5.5, 19.6, 9)

          doc.setFont('Times', 'bold')
          doc.text('No.', 14.4, 5.85)
          doc.text('PAPEL', 16.5, 5.85)

          // Iteramos sobre el array colPapels y escribimos los datos
          doc.setFont('Times', 'normal')

          if (item) {
            // Asegúrate de que colPapels no sea nulo, esté vacío y sea un array
            y = 6.4
            let num = 1
            try {
              const colPapels = JSON.parse(item.colPapels || '[]')
              // Verificamos que colPapels sea un array válido
              if (Array.isArray(colPapels) && colPapels.length > 0) {
                // Ahora puedes recorrer el array de colPapels
                colPapels.forEach(colPapel => {
                  // Lógica para agregar los detalles al PDF (como se mostró anteriormente)
                  doc.text(num.toString(), 14.5, y)
                  doc.text(colPapel.colorPapel || '', 15.3, y) // Ajusta las coordenadas
                  doc.text(colPapel.kgs || '', 19.8, y)
                  y += 0.4
                  num += 1
                })
              } else {
                console.log('No hay colPapels para esta orden.')
              }
            } catch (error) {
              console.error('"Error al obtener colPapels o generar PDF:"', error)
            }
          } else {
            console.log('No hay orden seleccionada')
          }
          doc.setFillColor(200, 200, 200)
          doc.roundedRect(0.5, 9.3, 20.6, 0.5, 0.1, 0.1, 'FD')
          doc.setFillColor(255, 255, 255)
          doc.roundedRect(0.5, 9.8, 20.6, 2.5, 0.1, 0.1, 'FD')
          doc.setFont('Times', 'bold')
          doc.setFontSize(10)
          doc.text('OBSERVACIONES', 9, 9.7)

          if (this.editedItem.observacionesCliente != null) {
            doc.setFontSize(10)
            doc.setFont('Times', 'normal')
            doc.text(this.editedItem.observacionesCliente, 0.8, 10.2)
          }
          doc.setFont('Times', 'normal')

          // Salto de linea de especificaciones de la orden
          // Definir el ancho máximo de la línea (en unidades de jsPDF)
          const maxWidth = 13 // Ajusta este valor según el ancho de tu hoja

          // Dividir el texto en líneas que caben dentro del ancho máximo
          const lines = doc.splitTextToSize(textoCorrido.toUpperCase(), maxWidth)

          // Definir las coordenadas iniciales
          const x = 0.7 // Coordenada X inicial
          y = 6.5 // Coordenada Y inicial

          // Agregar cada línea al PDF
          lines.forEach((line) => {
            doc.text(line, x, y) // Agregar la línea al PDF
            y += 0.4 // Ajustar la coordenada Y para la siguiente línea (ajusta el espaciado según sea necesario)
          })
        }

        doc.setFont('Times', 'bold')
        doc.text('Entregado por: ', 0.5, 13)
        doc.text(' _________________________', 3, 13)
        doc.text('Recibido por: ', 8, 13)
        doc.text(' __________________________', 10.3, 13)

        doc.save('orden.pdf')
        this.editedItem = { ...this.defaultItem }
        this.editedItemTintas = { ...this.editedItemTintasAux }
        this.editedIndex = -1
      } catch (error) {
        console.error('"Error al obtener colPapels o generar PDF:"', error)
      }
    },
    async imprimir (item) {
      try {
        this.editedIndex = this.ordenes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        // eslint-disable-next-line new-cap
        const doc = new jsPDF({
          orientation: 'portrait', // Cambiar a portrait si la orientación es vertical
          unit: 'mm', // Usar milímetros para mayor precisión
          format: [243, 280] // Configuración para tamaño oficio en Epson
        })
        // Separar fecha y hora
        const [fecha, hora] = this.editedItem.fechaHoraOrden.split(' ')

        // Fecha
        doc.setFontSize(12)
        doc.setFont('Courier', 'normal')
        doc.text(`${fecha}`, 133, 14)

        // Folio
        doc.text(this.editedItem.folio, 182, 14)

        // Hora
        doc.text(`${hora}`, 143, 24)

        // Vendedor
        doc.text(this.editedItem.nomUsuario, 190, 24)

        let y = 31
        let x = 35

        const infoTitleOrden = [
          { label: 'Cliente: ', value: this.editedItem.nombreCliente },
          { label: 'Ordenado por: ', value: this.editedItem.nombreOrdenado },
          { label: 'Nombre de la forma: ', value: this.editedItem.nombreForma },
          { label: 'Entrega a domicilio: ', value: this.editedItem.aDomicilio }
        ]
        // Iterar sobre los elementos y escribir en el PDF
        infoTitleOrden.forEach(item => {
          if (item.value != null) {
            if (item.label.includes('Entrega a domicilio')) {
              y += 1
              if (item.value === 1) {
                item.value = 'sí' // Cambiar a 'sí' si el valor es 1
                x = 47
              } else if (item.value === 0) {
                item.value = '' // Dejar vacío si el valor es 0
                x = 47
              }
            }
            doc.text(String(item.value), x, y) // Asegurarse de convertir el valor a cadena
          }
          y += 4 // Incrementar la posición Y para la siguiente línea
        })

        if (this.editedItem.telOrdenado) {
          doc.text(this.editedItem.telOrdenado, 150, 35)
        }

        if (this.editedItem.fechaEntregaOrden) {
          doc.text(this.editedItem.fechaEntregaOrden, 130, 39)
        }

        if (this.editedItem.horaEntregaOrden) {
          doc.text(this.editedItem.horaEntregaOrden, 187, 39)
        }

        if (this.editedItem.aDomicilio === 0 || this.editedItem.aDomicilio === null) {
          this.editedItem.aDomicilio = 'sí'
          doc.text(this.editedItem.aDomicilio, 150, 44)
        } else {
          this.editedItem.aDomicilio = ' '
          doc.text(this.editedItem.aDomicilio, 150, 44)
        }

        if (this.editedItem.cantidad != null) {
          doc.text(String(this.editedItem.cantidad), 20, 52)
        }
        if (this.editedItem.numTintas != null) {
          doc.text(String(this.editedItem.numTintas), 57, 52)
        }
        if (this.editedItem.selColor != null) {
          if (this.editedItem.selColor === 1) {
            this.editedItem.selColor = 'sí' // Cambiar a 'sí' si el valor es 1
          }
          doc.text(String(this.editedItem.selColor), 200, 52)
        }
        if (this.editedItem.nombrePapel != null) {
          if (this.editedItem.nombrePapel === 'BOND') {
            doc.text('sí', 30, 56)
          } else if (this.editedItem.nombrePapel === 'AUTOCOPIANTE') {
            doc.text('sí', 77, 56)
          } else if (this.editedItem.nombrePapel === 'COUCHE') {
            doc.text('sí', 112, 56)
          } else {
            doc.text(String(this.editedItem.nombrePapel), 143, 56)
          }
        }
        if (this.editedItem.nombreTamano != null) {
          doc.text(String(this.editedItem.nombreTamano), 179, 56)
        }
        if (this.editedItem.diseno != null) {
          if (this.editedItem.diseno === 1) {
            this.editedItem.diseno = 'sí' // Cambiar a 'sí' si el valor es 1
          }
          doc.text(String(this.editedItem.diseno), 20, 61)
        }
        if (this.editedItem.muestra != null) {
          if (this.editedItem.muestra === 1) {
            this.editedItem.muestra = 'sí' // Cambiar a 'sí' si el valor es 1
          }
          doc.text(String(this.editedItem.muestra), 87, 61)
        }
        if (this.editedItem.cambios != null) {
          if (this.editedItem.cambios === 1) {
            this.editedItem.cambios = 'sí' // Cambiar a 'sí' si el valor es 1
            doc.text(String(this.editedItem.cambios), 135, 61)
          }
        } else if (this.editedItem.cambios === null) {
          this.editedItem.cambios = 'sí'
          doc.text(String(this.editedItem.cambios), 175, 61)
        }
        if (this.editedItem.TipoImpresion === 'SOLO FRENTE') {
          doc.text('sí', 43, 65)
        }
        if (this.editedItem.TipoImpresion === 'AMBOS LADOS') {
          doc.text('sí', 80, 65)
        }
        if (this.editedItem.nombreTipoForma === 'NORMAL') {
          doc.text('sí', 122, 65)
        }
        if (this.editedItem.nombreTipoForma === 'CONTINUA') {
          doc.text('sí', 154, 65)
        }
        if (this.editedItem.nombreTipoForma === 'GTO') {
          doc.text('sí', 177, 65)
        }
        if (this.editedItem.nombreTipoForma === 'KORD') {
          doc.text('sí', 200, 65)
        }
        if (this.editedItem.nombreTipoForma && !['NORMAL', 'CONTINUA', 'GTO', 'KORD'].includes(this.editedItem.nombreTipoForma)) {
          doc.text(this.editedItem.nombreTipoForma, 121, 65)
        }
        if (this.editedItem.folioIn != null) {
          doc.text(String(this.editedItem.folioIn), 26, 69)
        }
        if (this.editedItem.folioFin != null) {
          doc.text(String(this.editedItem.folioFin), 60, 69)
        }
        if (this.editedItem.serie != null) {
          doc.text(String(this.editedItem.serie), 115, 69)
        }
        if (this.editedItem.blocks != null) {
          doc.text(String(this.editedItem.blocks), 165, 69)
        }

        if (item) {
          // Asegúrate de que colPapels no sea nulo, esté vacío y sea un array
          y = 86
          try {
            const colPapels = JSON.parse(item.colPapels || '[]')
            // Verificamos que colPapels sea un array válido
            if (Array.isArray(colPapels) && colPapels.length > 0) {
              // Ahora puedes recorrer el array de colPapels
              colPapels.forEach(colPapel => {
                const maxWidth = 45 // 45 mm en puntos jsPDF
                const colorPapelText = doc.splitTextToSize(colPapel.colorPapel || '', maxWidth)

                // Agrega la primera línea del texto
                doc.text(colorPapelText[0], 12, y)
                // Si hay líneas adicionales, imprímelas en la siguiente línea
                if (colorPapelText.length > 1) {
                  colorPapelText.slice(1).forEach((line, index) => {
                    y += 5 // Salta a la línea siguiente
                    doc.text(line, 12, y)
                  })
                } // Ajusta las coordenadas
                doc.text(colPapel.kgs || '', 57, y)
                doc.text(colPapel.marginales || '', 70, y)
                y += 5
              })
            } else {
              console.log('No hay colPapels para esta orden.')
            }
          } catch (error) {
            console.error('"Error al obtener colPapels o generar PDF:"', error)
          }
          try {
          // Obtener las tintas de la orden
            y = 51.5
            await this.getEspecformatinta(item)

            const tintas = this.editedItemTintas.idTinta || []
            // Verificamos que las tintas sean un array válido
            if (Array.isArray(tintas) && tintas.length > 0) {
              // Posición inicial (x) en puntos (1 cm = 28.3465 puntos)
              let x = 70 // Posición inicial en puntos (ajusta según tus necesidades)

              // Recorrer las tintas e imprimir cada una separada por 2 cm
              tintas.forEach((tinta, index) => {
                const nombreTinta = tinta.nombreTinta || ''

                // Imprimir el nombre de la tinta en la posición actual (x, y)
                doc.text(nombreTinta, x, y)

                // Mover la posición x 2 cm a la derecha (56.69 puntos)
                x += 20// 2 cm en puntos
              })

              y += 5 // Salto de línea para el siguiente contenido
            } else {
              console.log('No hay tintas para esta orden.')
            }
          } catch (error) {
            console.log('Error al obtener las tintas o generar PDF:', error)
          }
        } else {
          console.log('No hay orden seleccionada')
        }

        if (this.editedItem.nombreSellos != null) {
          if (this.editedItem.nombreSellos.toLowerCase() === 'madera'.toLowerCase()) {
            doc.text('sí', 131, 82)
            doc.text(this.editedItem.medida, 184, 82)
          }
          if (this.editedItem.nombreSellos.toLowerCase() === 'autoentintable'.toLowerCase()) {
            doc.text('sí', 145, 90)
            doc.text(this.editedItem.medida, 195, 90)
          }
          if (this.editedItem.nombreSellos.toLowerCase() === 'fechador'.toLowerCase()) {
            doc.text('sí', 134, 99)
          }
          if (this.editedItem.nombreSellos.toLowerCase() !== 'autoentintable'.toLowerCase() &&
          this.editedItem.nombreSellos.toLowerCase() !== 'fechador'.toLowerCase() &&
          this.editedItem.nombreSellos.toLowerCase() !== 'madera'.toLowerCase()) {
            this.editedItem.nombreSellos = this.editedItem.nombreSellos + ' ' + this.editedItem.tipo
            doc.text(this.editedItem.nombreSellos, 132, 107)
            doc.text(this.editedItem.medida, 170, 107)
          }
        }
        if (this.editedItem.tipo !== null) {
          if (this.editedItem.tipo.toLowerCase() === 'puño'.toLowerCase()) {
            doc.text('sí', 150, 82)
          }
          if (this.editedItem.tipo.toLowerCase() === 'moldura'.toLowerCase()) {
            doc.text('sí', 172, 82)
          }
          if (this.editedItem.tipo.toLowerCase() === 'autoentintable'.toLowerCase()) {
            doc.text('sí', 170, 99)
          }
          if (this.editedItem.tipo.toLowerCase() === 'normal'.toLowerCase()) {
            doc.text('sí', 197, 99)
          }
        }

        const maxWidth = 200 // Ancho máximo permitido para el texto
        if (this.editedItem.observacionesOrden != null) {
          // Divide el texto en líneas que se ajusten al ancho máximo
          const splitText = doc.splitTextToSize(this.editedItem.observacionesOrden, maxWidth)
          // Imprime el texto ajustado a la posición dada
          doc.text(splitText, 10, 130)
        }
        if (this.editedItem.nombreForma === 'SELLO') {
          doc.setFont('Courier', 'bold')
          doc.text('Sello', 15, 200)
          doc.setFont('Courier', 'normal')
          doc.text('Nombre: ', 10, 205)
          doc.text(this.editedItem.nombreSellos, 33, 205)
          doc.text('Tipo: ', 10, 210)
          doc.text(this.editedItem.tipo, 33, 210)
          doc.text('Medida: ', 10, 215)
          doc.text(this.editedItem.medida, 33, 215)
        }

        if (this.editedItem.nomUsuario != null) {
          doc.setFontSize(12)
          doc.setFont('Courier', 'normal')
          doc.text(this.editedItem.nomUsuario, 15, 265)
        }

        // Convertir el PDF a un Blob y abrirlo en una nueva pestaña
        // const pdfOutput = doc.output('blob')
        // const pdfURL = URL.createObjectURL(pdfOutput)

        // Configurar impresión automática
        doc.autoPrint()

        // Abrir el PDF en una nueva ventana y activar la impresión
        const string = doc.output('dataurlstring')
        const iframe = document.createElement('iframe')
        iframe.setAttribute('style', 'display:none')
        iframe.setAttribute('src', string)
        document.body.appendChild(iframe)
        iframe.contentWindow.print()
        // Abrir el PDF en una nueva pestaña
        // window.open(pdfURL)
        this.editedItem = { ...this.defaultItem }
        this.editedItemTintas = { ...this.editedItemTintasAux }
        this.editedIndex = -1
      } catch (error) {
        console.error('"Error al obtener colPapels o generar PDF:"', error)
      }
    }
  }
}
</script>
<style>

.tabla{
  padding: 0 35px;
}
.tablaTam {
  min-height: 700px;
}
th {
  background-color: #06a454;
  color: white;
  font-size: 1.2em;
}
tr{
  font-size: 1.1em;
}
tr:hover {background-color: #f2f2f2e2;}
.cardOrden {
  width: 700px;
  margin: auto;
  text-align: center;
}
.bottonusuarioOrdenes{
  text-align: right;
  padding: 0px 50px 0px 0px;
}
</style>
