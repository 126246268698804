<template>
  <v-app>
    <v-card class="mb-4">
      <v-layout>
        <v-app-bar color="#06a454"
        style="height: 70px;" prominent>
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>OSIC - version5</v-toolbar-title>
        <v-icon large class="mr-2">mdi-account</v-icon>
        <span class="mr-4">Bienvenido, {{ nombreUsuario }}</span>
        <v-btn icon @click="navigate('home')">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-app-bar>
        <v-navigation-drawer app :width="300"
          v-model="drawer"
          color="#06a454"
          rail
          @click="rail = false"
          expand-on-hover
        >

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              prepend-icon="mdi-account"
              title="Perfil"
              value="perfil"
              @click="navigate('PerfilAdmin')"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-account-group-outline"
              title="Usuarios"
              value="usuarios"
              @click="navigate('Usuarios')"
            ></v-list-item>
            <v-list-group value="ordenes">
              <template v-slot:activator="{ props }">
                <v-list-item
                  v-bind="props"
                  prepend-icon="mdi-clipboard-text"
                  title="Ordenes"
                ></v-list-item>
              </template>
              <v-list-item
              prepend-icon="mdi-clipboard-text-outline"
              title="Ordenes activas"
              value="ordenesA"
              @click="navigate('OrdenesPanelAdmin')"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-clipboard-text-multiple-outline"
              title="Ordenes registradas"
              value="ordenesR"
              @click="navigate('AllOrdenesPanelAdmin')"
            ></v-list-item>
            </v-list-group>
            <v-list-group value="ordenesPagadas">
              <template v-slot:activator="{ props }">
                <v-list-item
                  v-bind="props"
                  prepend-icon="mdi-card"
                  title="Registro pagos"
                ></v-list-item>
              </template>
              <v-list-item
              prepend-icon="mdi-card-outline"
              title="Ordenes entregadas"
              value="ordenesE"
              @click="navigate('OrdenesFacturacionAdmin')"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-card-remove-outline"
              title="Ordenes no pagadas"
              value="ordenesNP"
              @click="navigate('AllOrdenesFacturacionAdmin')"
            ></v-list-item>
            </v-list-group>
            <v-list-item
              prepend-icon="mdi-home-city"
              title="Clientes"
              value="clientes"
              @click="navigate('ClientesAdmin')"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-view-dashboard"
              title="Especificaciones"
              value="especificaciones"
              @click="navigate('EspecificacionesAdmin')"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-logout"
              title="Salir"
              value="salir"
              @click="navigate('home')"
            ></v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-main style="height: auto">
          <router-view></router-view>
        </v-main>
      </v-layout>
    </v-card>
  </v-app>
</template>

<script>
import backedosic from '@/components/api/apiosic.js'

export default {
  name: 'PanelAdminComponent',

  data () {
    return {
      drawer: true,
      rail: true,
      nombre: ''
    }
  },
  computed: {
    nombreUsuario () {
      return this.$store.state.user?.nombre || 'Usuario' // Asegúrate de que el nombre exista en el store
    }
  },
  methods: {
    async navigate (route) {
      if (route === 'home') {
        const token = localStorage.getItem('token')

        // Borra el token del almacenamiento local
        localStorage.removeItem('token')
        backedosic.post('/logout', { }, {
          headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
          console.log('Sesión cerrada correctamente')
          this.$router.push({ name: 'home' }) // Redirigir al login
        })
          .catch(error => {
            console.error('Error al cerrar sesión:', error.response.data)
          })
      } else {
        // Para otras rutas
        this.$router.push({ name: route })
      }
    },
    getToken () {
    // Implementa esta función para obtener el token del almacenamiento local o de donde lo tengas guardado
      return localStorage.getItem('token') // Ejemplo para obtenerlo del localStorage
    }
  }
}
</script>

<style>

</style>
