<template>
  <v-app>
    <br />
    <v-card-title class="text-h4 font-weight-medium" style="margin-left: 20px">
      Ordenes
    </v-card-title>
    <v-row style="margin-left: 1000px;">
      <v-col>
      <v-text-field
        v-model="search"
        label="Search"
        prepend-inner-icon="mdi-magnify"
        hide-details
        single-line
        variant="outlined"
        class="search"
      ></v-text-field>
      </v-col>
    <div>
      <v-col>
      <v-dialog v-model="dialog" class="pa-4 text-center" persistent>
        <div class="cardOrden">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <div v-if="step === 2">
              <v-container>
                <v-row>
                  <v-col>
                    <v-checkbox v-model="editedItem.urgente" label=Urgente: :true-value="1" :false-value="0" readonly></v-checkbox>
                  </v-col>
                  <row>
                    <v-col cols="6" md="2" sm="3">
                    <v-label
                    >{{ editedItem.fechaHoraOrden }}</v-label>
                  </v-col>
                  </row>
                  <row>
                    <v-col cols="6" md="2" sm="3">
                    <v-label
                    >{{ editedItem.folio }}</v-label>
                  </v-col>
                  </row>
                  </v-row>
                  <v-row>
                  <v-col>
                    <v-combobox v-model="editedItem.idCliente" :items="clientes" item-title="nomCliente"  item-value="idCliente"
                      label="Cliente" readonly>
                    </v-combobox>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col>
                    <v-combobox
                      label="Ordenado"
                      :items="ordenados"
                      item-title="nomOrdenado"
                      item-value="idOrdenado"
                      v-model="editedItem.idOrdenado"
                      readonly
                      ></v-combobox>
                  </v-col>
                  <v-col cols="10" md="3" sm="5">
                    <v-text-field
                      v-model="editedItem.telOrdenado"
                      readonly
                      label="Telefono"
                    ></v-text-field>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col>
                    <v-autocomplete
                      label="Forma"
                      :items="formas"
                      item-title="nombreForma"
                      item-value="idForma"
                      v-model = "editedItem.idForma"
                      readonly
                      ></v-autocomplete>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col>
                    <v-checkbox v-model="editedItem.aDomicilio" label="a Domicilio:" :true-value="1" :false-value="0" readonly></v-checkbox>
                  </v-col>
                  <v-col cols="7" md="3" sm="4">
                    <VueDatePicker v-model="editedItem.fechaEntregaOrden" :enable-time-picker="false" :week-numbers="{ type: 'iso' }" :min-date="new Date()" :max-date="maxDate" placeholder="Fecha entrega" readonly/>
                  </v-col>
                  <v-col cols="7" md="3" sm="4">
                    <VueDatePicker v-model="editedItem.horaEntregaOrden" time-picker position="right" placeholder="Hora" readonly/>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" variant="text" @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="#06a454" variant="text" @click="showStep(4)">
                  Siguiente
                </v-btn>
              </v-card-actions>
            </div>
            <div v-if="step === 3">
              <v-container>
                <v-row>
                  <v-col>
                    <v-checkbox v-model="editedItem.urgente" label="Urgente:" :true-value="1" :false-value="0"></v-checkbox>
                  </v-col>
                  <row>
                    <v-col cols="6" md="2" sm="3">
                    <v-label
                    >{{ editedItem.fechaHoraOrden }}</v-label>
                  </v-col>
                  </row>
                  <row>
                    <v-col cols="6" md="2" sm="3">
                    <v-label
                    >{{ editedItem.folio }}</v-label>
                  </v-col>
                  </row>
                  </v-row>
                  <v-row>
                  <v-col>
                    <v-combobox
                    v-model="editedItem.idCliente"
                    label="Cliente"
                    :items="clientes"
                    item-title="nomCliente"
                    item-value="idCliente"
                    readonly
                    ></v-combobox>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col>
                    <v-combobox
                      label="Ordenado"
                      :items="ordenados"
                      item-title="nomOrdenado"
                      item-value="idOrdenado"
                      v-model="editedItem.idOrdenado"
                      @update:modelValue="fetchTelOrdenado"
                      readonly
                      ></v-combobox>
                  </v-col>
                  <v-col cols="10" md="3" sm="5">
                    <v-text-field
                      v-model="editedItem.telOrdenado"
                      label="Telefono"
                      readonly
                    ></v-text-field>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col>
                    <v-checkbox v-model="editedItem.aDomicilio" label="a Domicilio:" :true-value="1" :false-value="0" readonly></v-checkbox>
                  </v-col>
                  <v-col cols="7" md="3" sm="4">
                    <VueDatePicker v-model="editedItem.fechaEntregaOrden" :enable-time-picker="false" :week-numbers="{ type: 'iso' }"  :min-date="new Date()" :max-date="maxDate" placeholder="Fecha entrega" readonly/>
                  </v-col>
                  <v-col cols="7" md="3" sm="4">
                    <VueDatePicker v-model="editedItem.horaEntregaOrden" time-picker position="right" placeholder="Hora" readonly/>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" variant="text" @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="#06a454" variant="text" @click="showStep(6)">
                  Siguiente
                </v-btn>
              </v-card-actions>
            </div>
            <div v-else-if="step === 4">
              <v-container>
                <v-row>
                  <v-col cols="10" md="3" sm="3">
                    <v-text-field
                      v-model="editedItem.cantidad"
                      label="cantidad"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10" md="2" sm="3">
                    <v-text-field
                      v-model="editedItem.numTintas"
                      label="Tintas"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10" md="4" sm="3">
                  <v-combobox
                    multiple
                    label="Color tintas"
                    v-model="editedItemTintas.idTinta"
                    :items="tintas"
                    item-title="nombreTinta"
                    item-value="idTinta"
                    readonly
                  ></v-combobox>
                  </v-col>
                  <v-col cols="10" md="3" sm="3">
                    <v-checkbox v-model="editedItem.selColor" label="S/color" :true-value="1" :false-value="0" readonly></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Papel"
                      :items="papels"
                      item-title="nombrePapel"
                      item-value="idPapel"
                      v-model="editedItem.idPapel"
                      readonly
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Tamaño"
                      :items="tamanos"
                      item-title="nombreTamano"
                      item-value="idTamano"
                      v-model="editedItem.idTamano"
                      readonly
                      ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" sm="6">
                    <v-checkbox v-model="editedItem.diseno" label="diseño:" value="1" :true-value="1" :false-value="0" readonly></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4" sm="6">
                    <v-checkbox v-model="editedItem.muestra" label="Muestra:" value="1" :true-value="1" :false-value="0" readonly></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4" sm="6">
                    <v-checkbox v-model="editedItem.cambios" label="Cambios:" value="1" :true-value="1" :false-value="0" readonly></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Impresion"
                      :items="impresions"
                      item-title="TipoImpresion"
                      item-value="idImpresion"
                      v-model="editedItem.idImpresion"
                      readonly
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Tipo Forma"
                      :items="tipoFormas"
                      item-title="nombreTipoForma"
                      item-value = "idTipoForma"
                      v-model="editedItem.idTipoForma"
                      readonly
                      ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="10" md="3" sm="3">
                    <v-text-field
                      v-model="editedItem.folioIn"
                      label="folioIn"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="3">
                    <v-text-field
                      v-model="editedItem.folioFin"
                      label="folioFin"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="3">
                    <v-text-field
                      v-model="editedItem.serie"
                      label="serie"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="3">
                    <v-text-field
                      v-model="editedItem.blocks"
                      label="blocks"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" variant="text" @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="gray-darken-1" variant="text" @click="showStep(2)">
                  Atras
                </v-btn>
                <v-btn color="#06a454" variant="text" @click="showStep(8)">
                  Siguiente
                </v-btn>
              </v-card-actions>
            </div>
            <div v-else-if="step === 5">
              <v-container>
                <v-row>
                  <v-col>
                    <v-textarea
                    v-model="editedItem.observacionesOrden"
                    bg-color="grey-lighten-2"
                    label="Observaciones"
                    readonly
                  ></v-textarea>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col cols="14" md="6" sm="8">
                    <v-text-field
                        label="Elaboró"
                        v-model="getNombreUsuario"
                        readonly
                        outlined
                      ></v-text-field>
                  </v-col>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Diseño"
                      :items="usuarios"
                      item-title="nomUsuario"
                      item-value="idUsuario"
                      readonly
                      ></v-autocomplete>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Reviso"
                      :items="usuarios"
                      item-title="nomUsuario"
                      item-value="idUsuario"
                      readonly
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Imprimio"
                      :items="usuarios"
                      item-title="nomUsuario"
                      item-value="idUsuario"
                      readonly
                      ></v-autocomplete>
                  </v-col>
                  </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" variant="text" @click="showStep(8)">
                  Atras
                </v-btn>
                <v-btn color="#06a454" variant="text" @click="close">
                  Salir
                </v-btn>
              </v-card-actions>
            </div>
            <div v-else-if="step === 6">
              <!-- Aquí irían los campos para los sellos -->
              <v-container>
                <v-row>
                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.cantidad"
                      label="cantidad"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.nombreSellos"
                      label="Sello"
                      readonly
                    ></v-text-field>
                    </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.tipo"
                      label="Tipo"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.medida"
                      label="Medida"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" variant="text" @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="gray-darken-1" variant="text" @click="showStep(3)">
                  Atras
                </v-btn>
                <v-btn color="#06a454" variant="text" @click="showStep(7)">
                  Siguiente
                </v-btn>
              </v-card-actions>
            </div>
            <div v-else-if="step === 7">
              <v-container>
                <v-row>
                  <v-col>
                    <v-textarea
                    v-model="editedItem.observacionesOrden"
                    bg-color="grey-lighten-2"
                    label="Observaciones"
                    readonly
                  ></v-textarea>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col cols="14" md="6" sm="8">
                    <v-text-field
                        label="Elaboró"
                        v-model="getNombreUsuario"
                        readonly
                        outlined
                      ></v-text-field>
                  </v-col>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Diseño"
                      :items="usuarios"
                      item-title="nomUsuario"
                      item-value="idUsuario"
                      readonly
                      ></v-autocomplete>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Reviso"
                      :items="usuarios"
                      item-title="nomUsuario"
                      item-value="idUsuario"
                      readonly
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="14" md="6" sm="8">
                    <v-autocomplete
                      label="Imprimio"
                      :items="usuarios"
                      item-title="nomUsuario"
                      item-value="idUsuario"
                      readonly
                      ></v-autocomplete>
                  </v-col>
                  </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" variant="text" @click="showStep(6)">
                  Atras
                </v-btn>
                <v-btn color="#06a454" variant="text" @click="close">
                  Salir
                </v-btn>
              </v-card-actions>
            </div>
            <div v-else-if="step === 8">
              <v-container>
                <v-row>
                  <v-data-table-virtual
                      class="tabla"
                      :headers="headersColPapels"
                      :items="colPapels"
                      :sort-by="[{ key: 'fecha', order: 'asc'}]"
                      :padding="100"
                      item-key="idColPapel"
                    >
                    </v-data-table-virtual>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" variant="text" @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="gray-darken-1" variant="text" @click="showStep(4)">
                  Atras
                </v-btn>
                <v-btn color="#06a454" variant="text" @click="showStep(5)">
                  Siguiente
                </v-btn>
              </v-card-actions>
            </div>
            </v-card-text>
        </v-card>
        </div>
      </v-dialog>
      </v-col>
    </div>
    </v-row>
    <v-card-text>
      <v-data-table
        class="tabla tablaTam"
        :headers="headers"
        :items="ordenes"
        :sort-by="[{ key: 'fecha', order: 'asc'}]"
        :padding="100"
        item-key="idOrden"
        :search="search"
        v-model="ordenSeleccionada"
        single-select
      >
        <template v-slot:[`item.facturado`] ="{item}">
          <template v-if="!item.showSelectFacturado">
            <v-chip :color="getFacturadoColor(item.facturado)" class="ma-2" label size="large" @click="toggleSelectFacturado(item)">
              {{ item.facturado === null ? 'Seleccionar' : (item.facturado ? 'Emitido' : 'Recibo')}}
          </v-chip>
          </template>
          <tr v-else>
                <td colspan="2">
                    <v-select v-model="item.facturado" :items="facturadoOptions" item-title="text" item-value="value" @update:model-value="handleFacturadoChange(item)"
                    @blur="toggleSelectFacturado(item)" :readonly="item.facturado !== null || nombreUsuario.tipoUsuario === 1"></v-select>
                </td>
          </tr>
        </template>
        <template v-slot:[`item.nomPagado`] ="{item}">
          <template v-if="!item.showSelect" >
            <v-chip :color="getPagado(item.nomPagado)" class="ma-2" label size="large" @click="toggleSelect(item)">
              {{ item.nomPagado || 'No pagado' }}
          </v-chip>
          </template>
          <tr v-else>
                <td colspan="2">
                    <v-select v-model="item.nomPagado" :items="pagado" item-title="nomPagado" item-value="idPagado" @update:model-value="handleOptionChangePagado(item)"
                    @blur="toggleSelect(item)" :readonly="nombreUsuario.tipoUsuario === 1"></v-select>
                </td>
          </tr>
        </template>
        <template v-slot:top></template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="me-2" size="small" @click="editItem(item)">
            mdi-eye
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">Reset</v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-dialog v-model="showFacturaModal" max-width="500" persistent>
      <v-card>
        <v-card-title class="text-center">Agregar número de factura</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="editedItemFactura.numFactura"
            label="Número de Factura"
            outlined
            :rules="[rules.required]"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="gray-darken-1" @click="closeFacturaModal">Cancelar</v-btn>
          <v-btn color="#06a454" @click="saveFactura">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-card-actions>
    <v-dialog v-model="showErrorDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="text-center">
          <v-icon color="red" large class="mr-2">mdi-alert-circle</v-icon>
          Atención</v-card-title>
          <v-card-text class="text-center">
            Por favor, Complete todos los campos obligatorios antes de continuar.
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#06a454" text @click="showErrorDialog = false">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import backedosic from '@/components/api/apiosic.js'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { ref } from 'vue'

export default {
  name: 'OrdenesFacturacionComponent',
  components: { VueDatePicker },
  props: {
    endpoint: {
      type: String,
      required: true
    }
  },
  data: () => ({
    // Controla si el modal está abierto o cerrado
    showFacturaModal: false,
    // Almacena el número de factura ingresado por el usuario
    numeroFactura: '',
    // Almacena el item actual que se está editando
    currentItem: null,
    search: '',
    date: null,
    showSelect: false,
    showSelectFacturado: false,
    maxDate: ref(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() + 15)),
    dialog: false,
    dialogDelete: false,
    step: 1,
    headers: [
      {
        title: 'Folio',
        align: 'start',
        sortable: false,
        key: 'folio',
        background: 'red-lighten-5'
      },
      { title: 'Forma', key: 'nombreForma', class: 'my-header-style' },
      { title: 'Cantidad', key: 'cantidad' },
      { title: 'Fecha', key: 'fechaHoraOrden' },
      { title: 'Cliente', key: 'nombreCliente' },
      { title: 'Ordeno', key: 'nombreOrdenado' },
      { title: 'Usuario', key: 'nomUsuario' },
      { title: 'Comprobante', key: 'facturado' },
      { title: '# Folio', key: 'numFactura' },
      { title: 'Pagado', key: 'nomPagado' },
      { title: '     ', key: 'actions', sortable: false, width: '150px' }
    ],
    headersColPapels: [
      { title: 'Color papel', key: 'colorPapel' },
      { title: 'kgs', key: 'kgs' },
      { title: 'marginales', key: 'marginales' },
      { title: '     ', key: 'actions', sortable: false }
    ],
    editedItemColPapels: {
      colorPapel: '',
      kgs: '',
      marginales: '',
      idColPapel: '',
      idEspecificacionesForma: ''
    },
    editedItemTintas: {
      idTinta: [],
      nombreTinta: []
    },
    tintas: [],
    ordenes: [],
    editedIndex: -1,
    editedIndexAux: -1,
    editedItemFactura: {
      numFactura: ''
    },
    editedItem: {
      placa: '1',
      numTintas: '',
      selColor: '',
      diseno: '',
      muestra: '',
      cambios: '',
      folioIn: '',
      folioFin: '',
      serie: '',
      blocks: '',
      idImpresion: '',
      TipoImpresion: '',
      idPapel: '',
      nombrePapel: '',
      idTipoForma: '',
      nombreTipoForma: '',
      idTamano: '',
      nombreTamano: '',
      idForma: '',
      nombreForma: '',
      folio: '',
      cantidad: '',
      fechaHoraOrden: '',
      fechaEntregaOrden: '',
      horaEntregaOrden: '',
      aDomicilio: '',
      urgente: '',
      observacionesOrden: '',
      idOrdenado: '',
      nomOrdenado: '',
      telOrdenado: '',
      idCliente: '',
      nomCliente: '',
      idUsuario: '',
      nomUsuario: '',
      idSellos: '',
      medida: '',
      tipo: '',
      idEstado: '',
      idEspecificacionesForma: ''
    },
    facturadoOptions: [
      { text: 'Emitido', value: 1 },
      { text: 'Recibo', value: 0 }
    ],
    clientes: [],
    ordenados: [],
    formas: [],
    papels: [],
    tamanos: [],
    impresions: [],
    tipoFormas: [],
    usuarios: [],
    colPapels: [],
    pagado: [],
    expandedItems: [],
    ordenSeleccionada: null,
    showErrorDialog: false,
    nextId: 1,
    rules: {
      required: value => !!value || 'Campo requerido.'
    }
  }),

  computed: {
    getNombreUsuario () {
      return this.editedIndex === -1 ? this.nombreUsuario.nombre : this.editedItem.nomUsuario
    },
    nombreUsuario () {
      const user = this.$store.state.user
      return {
        nombre: user?.nombre || 'Usuario',
        idUsuario: user?.idUsuario || null,
        tipoUsuario: user?.tipoUsuario || null

      }
    }
  },
  mounted () {
    this.$store.dispatch('fetchUser')
      .then(() => {
        console.log('usuario después de fetchUser:', this.$store.state.user)
      })
      .catch(error => {
        console.error('Error al obtener el usuario:', error)
      })
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },

  created () {
    this.initialize()
  },

  methods: {
    initialize (retries = 3, delay = 1000) {
      backedosic.get(this.endpoint)
        .then(r => {
          this.ordenes = r.data
          this.getCliente()
          this.getOrdenado()
          this.getForma()
          this.getPapel()
          this.getTamano()
          this.getImpresion()
          this.getTipoForma()
          this.getTintas()
          this.getUsuario()
          this.getTipoPago()
        })
        .catch(error => {
          if (error.response && error.response.status === 429 && retries > 0) {
            setTimeout(() => {
              this.initialize(retries - 1, delay * 2)
            }, delay)
          } else {
            console.error('Error al obtener datos:', error)
          }
        })
    },
    async getColPapels () {
      this.colPapels = []
      this.editedItemColPapels.idEspecificacionesForma = String(this.nombreUsuario.idUsuario)
      this.editedItem.idEspecificacionesForma = this.editedItem.idEspecificacionesForma || '0'
      if (this.editedItem && this.editedItem.idEspecificacionesForma) {
        backedosic.get(`/getColPapelsByID/${this.editedItemColPapels.idEspecificacionesForma}/${this.editedItem.idEspecificacionesForma}`).then(r => {
          this.colPapels = r.data
          console.log('colpapels', this.colPapels)
        }).catch(error => {
          console.error('Error:', error.response ? error.response.data : error.message)
        })
      } else {
        console.warn('idEspecificacionesForma no está definido, no se puede hacer la solicitud.')
      }
    },
    async getEspecformatinta (item) {
      try {
        const response = await backedosic.get(`/especforma-tinta/${item.idEspecificacionesForma}`)
        this.editedItemTintas.idTinta = response.data.map(tinta => ({
          idTinta: tinta.idTinta,
          nombreTinta: tinta.nombreTinta
        }))
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log('No se encontraron tintas para esta especificación.')
          this.editedItemTintas.idTinta = []
        } else {
          console.log('Error al obtener las tintas:', error)
        }
      }
    },
    async getCliente () {
      this.clientes = []
      try {
        const response = await backedosic.get('/cliente')
        response.data.forEach(e => {
          this.clientes.push({ idCliente: e.idCliente, nomCliente: e.nomCliente })
        })
      } catch (error) {
        console.error('Error al obtener los clientes:', error)
      }
    },

    async getTintas () {
      this.tintas = []
      try {
        const response = await backedosic.get('/tinta')
        response.data.forEach(e => {
          this.tintas.push({ idTinta: e.idTinta, nombreTinta: e.nombreTinta })
        })
      } catch (error) {
        console.error('Error al obtener las tintas:', error)
      }
    },

    async getOrdenado () {
      this.ordenados = []
      try {
        const response = await backedosic.get('/ordenado')
        response.data.forEach(e => {
          this.ordenados.push({ idOrdenado: e.idOrdenado, nomOrdenado: e.nomOrdenado, telOrdenado: e.telOrdenado })
        })
      } catch (error) {
        console.error('Error al obtener los clientes:', error)
      }
    },
    getForma () {
      this.formas = []
      backedosic.get('/forma').then(r => {
        r.data.forEach(e => {
          this.formas.push({ idForma: e.idForma, nombreForma: e.nombreForma })
        })
      })
    },
    getPapel () {
      this.papels = []
      backedosic.get('/papel').then(r => {
        r.data.forEach(e => {
          this.papels.push({ idPapel: e.idPapel, nombrePapel: e.nombrePapel })
        })
      })
    },
    getTamano () {
      this.tamanos = []
      backedosic.get('/tamano').then(r => {
        this.tamanos = r.data
      })
    },
    getImpresion () {
      this.impresions = []
      backedosic.get('/impresion').then(r => {
        this.impresions = r.data
      })
    },
    getTipoForma () {
      this.tipoFormas = []
      backedosic.get('/tipoforma').then(r => {
        r.data.forEach(e => {
          this.tipoFormas.push({ idTipoForma: e.idTipoForma, nombreTipoForma: e.nombreTipoForma })
        })
      })
    },
    getUsuario () {
      this.usuarios = []
      backedosic.get('/usuario').then(r => {
        this.usuarios = r.data
      })
    },
    getTipoPago () {
      if (this.pagado.length === 0) {
        backedosic.get('/pagado').then(r => {
          this.pagado = r.data
        })
      }
    },

    showStep (step) {
      this.step = step
    },

    editItem (item) {
      this.editedIndex = this.ordenes.indexOf(item)
      this.editedItem = Object.assign({}, item)

      this.dialog = true

      if (this.editedItem.nombreSellos && this.editedItem.nombreSellos.trim() !== '') {
        this.step = 3
      } else {
        this.step = 2
        this.getColPapels()
        this.getEspecformatinta(item)
      }
    },

    close () {
      this.dialog = false
      this.step = 1
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemTintas = Object.assign({}, this.editedItemColPapelsAux)
        this.editedIndex = -1
      })
    },

    getPagado (idPagado) {
      if (idPagado === 'Efectivo') return 'green'
      else if (idPagado === 'Transferencia') return 'teal'
      else if (idPagado === 'Tarjeta Debito') return 'indigo'
      else if (idPagado === 'Trajeta Credito') return 'orange'
      else if (idPagado === 'Cheque') return 'pink'
      else if (!idPagado) return 'red'
    },

    isExpanded (item) {
      return this.expandedItems.includes(item)
    },
    toggleSelect (item) {
      item.showSelect = !item.showSelect
    },
    toggleSelectFacturado (item) {
      item.showSelectFacturado = !item.showSelectFacturado
    },
    handleOptionChangePagado (item) {
      backedosic.put(`/orden/changePagado/${item.idOrden}/${item.nomPagado}`)
        .then(response => {
          console.log('Estado actualizado en la base de datos:', response.data)
          this.initialize()
        })
        .catch(error => {
          console.error('Error al actualizar el estado en la base de datos:', error)
        })
      item.showSelect = false
    },
    // Maneja el cambio de estado de facturación
    handleFacturadoChange (item) {
      if (item.facturado === 1 || item.facturado === 0) {
        // Si se selecciona "Facturado", abre el modal
        this.currentItem = item
        this.showFacturaModal = true
      } else {
        // Si se selecciona "No Facturado", no se necesita número de factura
        this.updateFacturado(item) // Envía null como número de factura
        this.initialize()
      }
    },

    // Guarda el número de factura y cierra el modal
    saveFactura () {
      if (this.currentItem && this.editedItemFactura.numFactura) {
        this.updateFacturado(this.currentItem) // Envía el número de factura
      } else {
        this.showErrorDialog = true
      }
    },
    // Cierra el modal y limpia los datos
    closeFacturaModal () {
      this.showFacturaModal = false
      this.currentItem = null
      this.editedItemFactura.numFactura = ''
      this.initialize()
    },
    async updateFacturado (item) {
      try {
        await backedosic.put(`/orden/facturado/${item.idOrden}/${item.facturado}`)
          .then(response => {
            console.log('Facturado actualizado en la base de datos:', response.data)
          })
          .catch(error => {
            console.error('Error al actualizar el estado en la base de datos:', error)
          })
      } catch (error) {
        console.error('Error al actualizar el estado de facturación:', error)
      }
      try {
        await backedosic.post(`/factura/${item.idOrden}`, this.editedItemFactura)
          .then(response => {
            console.log('Numero de factura actualizado en la base de datos:', response.data)
            this.initialize()
          })
          .catch(error => {
            console.error('Error al actualizar el numero de factura en la base de datos:', error)
          })
      } catch (error) {
        console.error('Error al actualizar el numero de factura:', error)
      }
      this.closeFacturaModal()
    },
    getFacturadoColor (facturado) {
      return facturado === null ? 'gray' : (facturado ? 'green' : 'red')
    }
  }
}
</script>
<style>

.tabla{
  padding: 0 35px;
}
.tablaTam {
  min-height: 700px;
}
th {
  background-color: #06a454;
  color: white;
  font-size: 1.2em;
}
tr{
  font-size: 1.1em;
}
tr:hover {background-color: #f2f2f2e2;}
.cardOrden {
  width: 700px;
  margin: auto;
  text-align: center;
}
.bottonusuarioOrdenes{
  text-align: right;
  padding: 0px 50px 0px 0px;
}
.search {
  padding: 0 30px;
}
.custom-disabled-select {
  opacity: 1; /* Evita que se vea opaco */
  pointer-events: none; /* Evita interacciones */
}
</style>
