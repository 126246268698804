<template>
  <PanelFacturacion />
</template>

<script>
import { defineComponent } from 'vue'

import PanelFacturacion from '../components/PanelFacturacion.vue'

export default defineComponent({
  name: 'PanelFacturacionView',

  components: {
    PanelFacturacion
  }
})
</script>
