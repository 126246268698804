import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import PanelAdminView from '@/views/PanelAdminView.vue'
import PanelUsuarioView from '@/views/PanelUsuarioView.vue'
import PanelFacturacionView from '@/views/PanelFacturacionView.vue'
import OrdenesView from '@/views/OrdenesView.vue'
import OrdenesFacturacionView from '@/views/OrdenesFacturacionView.vue'
import ClientesView from '@/views/ClientesView.vue'
import EspecificacionesView from '@/views/EspecificacionesView.vue'
import PerfilView from '@/views/PerfilView.vue'
import UsuariosView from '@/views/UsuariosView.vue'
import backedosic from '@/components/api/apiosic.js'
import AllOrdenesView from '@/views/AllOrdenesView.vue'
import OrdenesNPFacturacionView from '@/views/OrdenesNPFacturacionView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginView
  },
  {
    path: '/panelAdmin',
    name: 'PanelAdmin',
    component: PanelAdminView,
    children: [
      {
        path: 'ordenes',
        name: 'OrdenesPanelAdmin',
        component: OrdenesView
      },
      {
        path: 'Allordenes',
        name: 'AllOrdenesPanelAdmin',
        component: AllOrdenesView
      },
      {
        path: 'clientes',
        name: 'ClientesAdmin',
        component: ClientesView
      },
      {
        path: 'especificaciones',
        name: 'EspecificacionesAdmin',
        component: EspecificacionesView
      },
      {
        path: 'perfil',
        name: 'PerfilAdmin',
        component: PerfilView
      },
      {
        path: 'usuarios',
        name: 'Usuarios',
        component: UsuariosView
      },
      {
        path: 'ordenes',
        name: 'OrdenesFacturacionAdmin',
        component: OrdenesFacturacionView
      },
      {
        path: 'Allordenes',
        name: 'AllOrdenesFacturacionAdmin',
        component: OrdenesNPFacturacionView
      }
    ]
  },
  {
    path: '/panelUsuario',
    name: 'PanelUsuario',
    component: PanelUsuarioView,
    children: [
      {
        path: 'ordenes',
        name: 'OrdenesPanelUsuario',
        component: OrdenesView
      },
      {
        path: 'Allordenes',
        name: 'AllOrdenesPanelUsuario',
        component: AllOrdenesView
      },
      {
        path: 'clientes',
        name: 'ClientesUsuario',
        component: ClientesView
      },
      {
        path: 'especificaciones',
        name: 'EspecificacionesUsuario',
        component: EspecificacionesView
      },
      {
        path: 'perfil',
        name: 'PerfilUsuario',
        component: PerfilView
      },
      {
        path: 'ordenes',
        name: 'OrdenesFacturacionUsuario',
        component: OrdenesFacturacionView
      },
      {
        path: 'Allordenes',
        name: 'AllOrdenesFacturacionUsuario',
        component: OrdenesNPFacturacionView
      }
    ]
  },
  {
    path: '/panelFacturacion',
    name: 'PanelFacturacion',
    component: PanelFacturacionView,
    children: [
      {
        path: 'ordenes',
        name: 'OrdenesFacturacion',
        component: OrdenesFacturacionView
      },
      {
        path: 'Allordenes',
        name: 'AllOrdenesFacturacion',
        component: OrdenesNPFacturacionView
      },
      {
        path: 'perfil',
        name: 'PerfilFacturacion',
        component: PerfilView
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// Función para validar el token
async function validarToken () {
  const token = localStorage.getItem('token')
  if (!token) {
    return false // No hay token, no se hace la petición
  }

  try {
    const response = await backedosic.post('/validar-token', {}, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return response.status === 200
  } catch (error) {
    // Manejar errores 401 sin imprimir en consola
    if (error.response && error.response.status === 401) {
      console.log('Token inválido o expirado')
      localStorage.removeItem('token') // Eliminar token inválido
    } else {
      console.log('error', error.message) // Mostrar otros tipos de errores si ocurre
    }
    return false // Retorna false si hay cualquier tipo de error
  }
}

// Guarda de navegación global
router.beforeEach(async (to, from, next) => {
  const isPublicRoute = ['home'].includes(to.name)
  const isAuthenticated = await validarToken() // Verificar si el token es válido

  if (isPublicRoute) {
    next() // Permitir el acceso a rutas públicas
  } else {
    if (isAuthenticated) {
      console.log('Usuario autenticado, permitiendo acceso.')
      next() // Permitir el acceso a rutas protegidas
    } else {
      console.log('Usuario no autenticado o token expirado, redirigiendo a login.')
      next({ name: 'home' }) // Redirigir a la página de login si no está autenticado
    }
  }
})
export default router
