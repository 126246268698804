<template>

  <Ordenes endpoint="/orden" ></Ordenes>

</template>

<script>
import { defineComponent } from 'vue'

import Ordenes from '../components/Ordenes.vue'

export default defineComponent({
  name: 'AllOrdenesView',

  components: {
    Ordenes
  }
})
</script>
